<template lang="pug">
.kr-wallet-tabs(kr-resource, v-if="isVisible")
  .kr-wallet-tab(
    v-for="tab of tabs",
    :disabled="isThereNoCard",
    :class="[tab.class, tab.id === walletMode ? 'kr-selected' : '', isThereNoCard && tab.id !== walletMode ? 'kr-disabled' : '']",
    @click="changeWalletTab(tab.id)"
  )
    span {{ labels[tab.label] }}
</template>

<script>
import _ from 'underscore'
import { mapState, mapGetters } from 'vuex'
import { dynamicMapState, dynamicMapActions } from '@/common/util/store'

export default {
  name: 'KryptonWalletTabs',
  inject: ['context'],
  data() {
    return {
      tabs: [
        {
          label: 'wallet_tab_mycard',
          id: 'myCards',
          class: 'kr-my-cards'
        },
        {
          label: 'wallet_tab_newcard',
          id: 'newCard',
          class: 'kr-new-card'
        }
      ],
      labels: {}
    }
  },
  computed: {
    ...mapState(['formMode', 'formToken', 'language', 'dna']),
    ...mapGetters(['translate', 'isCardFormVisible']),
    ...dynamicMapState('context.namespace', ['walletMode']),
    isVisible() {
      return (
        this.isCardFormVisible(this.context.formId) &&
        this.formMode === 'wallet'
      )
    },
    isThereOnlyOneCard() {
      return this.dna?.tokens?.cards?.length === 1
    },
    isThereNoCard() {
      return this.dna?.tokens?.cards?.length === 0
    },
    multipleCards() {
      return this.dna?.tokens?.cards?.length > 1
    }
  },
  watch: {
    formMode(val) {
      if (val === 'wallet') this.changeWalletTab('myCards')
      this.updateClassName()
    },
    language: 'syncLabels',
    dna: {
      deep: true,
      handler: 'syncLabels'
    },
    walletMode(val, oldVal) {
      this.fieldClear()
      this.resetBrand()

      const $formEl = this.$el.parentElement
      if ($formEl) {
        if (oldVal)
          $formEl.classList.remove(`kr-wallet-${oldVal.toLowerCase()}`)
        if (val) $formEl.classList.add(`kr-wallet-${val.toLowerCase()}`)
      }
    }
  },
  created() {
    this.syncLabels()
    if (this.formMode === 'wallet') this.changeWalletTab('myCards')
  },
  mounted() {
    this.updateClassName()
  },
  methods: {
    ...dynamicMapActions('context.namespace', [
      'changeWalletTab',
      'fieldClear',
      'resetBrand'
    ]),
    syncLabels() {
      this.labels = {}
      for (const tab of this.tabs) {
        let label = tab.label
        if (tab.id === 'myCards' && this.multipleCards) label = `${label}s`
        this.labels[tab.label] = this.translate(label)
      }
    },
    updateClassName() {
      const $formEl = this.$el.parentElement
      if ($formEl)
        $formEl.classList[this.formMode === 'wallet' ? 'add' : 'remove'](
          'kr-wallet'
        )
    }
  }
}
</script>
