var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-opm-group" },
    [
      _vm.hasBack
        ? _c("SmartFormGridBackButton", {
            attrs: { method: _vm.group },
            on: {
              back: function ($event) {
                return _vm.$emit("back")
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "kr-grid-group", style: _vm.gridStyles },
        _vm._l(_vm.filteredMethods, function (method) {
          return _c("SmartFormGridOpmButton", {
            directives: [
              {
                name: "onresize",
                rawName: "v-onresize",
                value: _vm.onResize,
                expression: "onResize",
              },
            ],
            key: method.key,
            attrs: {
              method: method,
              locked: _vm.allLocked,
              group: _vm.group,
              "group-methods": _vm.groupMethods,
              "group-position": _vm.filteredMethods.findIndex(function (item) {
                return item.key === method.key
              }),
              "show-label": _vm.showLabels,
              "margin-btns": _vm.splitMethods,
            },
            on: {
              selected: _vm.onSelected,
              "hide-label": function ($event) {
                return _vm.updateVisibleLabels(method.key, false)
              },
              "show-label": function ($event) {
                return _vm.updateVisibleLabels(method.key, true)
              },
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }