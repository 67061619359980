<template lang="pug">
  button.kr-method-btn.kr-method-btn--brand-cards(
    v-on:click="clickHandler"
    v-on:mouseover="hover = true"
    v-on:mouseleave="hover = false"
    :class="[walletDynamicClasses, brandClass]"
    :data-card="value"
    :data-token="method.token"
    type="button"
  )
    SmartFormRadioButton(v-if="hasRadioButton", :checked="isSelected", :hover="hover || hoverDelete")
    .kr-icon
      BrandIcon(:method="method.brand")
    SmartFormMaskedCardLabel.kr-method-btn__label(:value="formatCardNumber(value, method.brand)")
    SmartFormGridWalletDelete(
      v-if="!isLoading"
      v-on:click.stop="onDeleteClick"
      v-on:mouseover.stop="hoverDelete = true"
      v-on:mouseleave.stop="hoverDelete = false"
      :token="method.token"
      :method="`CARDS:${method.brand}`"
    )
    KryptonLoadingAnimation(v-else)
</template>

<script>
import WalletButtonMixin from '@/host/components/mixins/WalletButton'
import SmartFormMaskedCardLabel from '@/host/components/smartform/MaskedCardLabel'
import { formatCardNumber } from '@/common/service/CardUtils'

export default {
  name: 'SmartFormGridWalletCardButton',
  components: { SmartFormMaskedCardLabel },
  mixins: [WalletButtonMixin],
  computed: {
    brandClass() {
      return {
        [`kr-method-btn--brand-${this.method.brand.toLowerCase()}`]: true
      }
    },
    value() {
      return this.method.fields.pan.value
    },
    methodType() {
      return 'CARDS'
    },
    methodKey() {
      return `${this.methodType}:${this.method.token}`
    }
  },
  methods: {
    formatCardNumber(card, brand) {
      return formatCardNumber(card, brand)
    },
    openCard() {
      if (this.isDisabled || this.isLoading) return
      this.$emit('selected', this.methodKey)
    },
    deleteCardHandler() {
      this.deleteCard(`CARDS:${this.method.brand}`, this.method.token)
    },
    async clickHandler() {
      if (!this.amount) {
        this.deleteCardHandler()
        return
      }

      if (await this.interruptsExecution('WALLET_CARDS', 'openPopin'))
        return false

      this.openCard()
    }
  }
}
</script>
