import _ from 'underscore'
import I18nConfiguration from '@/configuration/I18nConfiguration'

export const getSupported = languageOrCulture => {
  const localeAlias = I18nConfiguration.alias
  const localeFallback = 'en'

  // Fix it seems a bad letter case culture
  if (_.isEmpty(languageOrCulture)) return localeFallback

  if (/^[a-z]{2}-[a-z]{2}$/.test(languageOrCulture.toLowerCase())) {
    let splittedLanguage = languageOrCulture.split('-')
    return (
      splittedLanguage[0].toLowerCase() +
      '-' +
      splittedLanguage[1].toUpperCase()
    )
  }

  if (languageOrCulture in localeAlias) return languageOrCulture

  return localeFallback
}
