<template lang="pug">
.kr-simple-modal-wrapper(v-show="show" :class="dynamicClasses.wrapper" ref="content")
  .kr-simple-modal
    header.kr-simple-modal-header
      BrandIcon(:method="modalData.method")
      h2.kr-simple-modal-header-title {{ translate(this.title) }}
      CloseButton(:is-visible="true" v-on:close="onAction('closeModal', 'close')")
    main.kr-simple-modal-content
      section.kr-simple-modal-content-main
        p(v-for="item in content" v-html="contentHtml(item)")
      section.kr-simple-modal-content-optional(v-if="hasOptionalContent")
        .kr-simple-modal-content-optional-item(v-for="item in optionalContent")
          span(v-html="optionalLabelHtml(item)")
          a(v-if="item.link", :href="optionalLinkHref(item)" v-html="optionalLinkHtml(item)")
    footer.kr-simple-modal-footer(:class="dynamicClasses.verticalButtons")
      button(
        type="button", 
        v-for="button in buttons", 
        :class="[`kr-btn-${button.type}`, button.action !== 'closeModal' ? `kr-btn-action` : '', loading ? 'kr-loading' : '' ]", 
        @click="onAction(button.action, button.name, button.loading)"
        v-html="buttonHtml(button.action, button.label)"
      )
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { isBoolean, isFunction } from 'underscore'
import DetachableMixin from '@/host/components/mixins/Detachable'
import AnimatedModalMixin from '@/host/components/mixins/AnimatedModal'
import BrandIcon from '@/host/components/controls/BrandIcon'
import CloseButton from '@/common/components/CloseButton'

export default {
  name: 'SimpleModal',
  components: { BrandIcon, CloseButton },
  mixins: [DetachableMixin, AnimatedModalMixin],
  computed: {
    ...mapState(['selectedBrand']),
    ...mapState({
      title: state => state.modal.layout.title,
      content: state => state.modal.layout.content,
      optionalContent: state => state.modal.layout.optional,
      buttons: state => state.modal.layout.buttons,
      modalData: state => state.modal.data,
      loading: state => state.modal.loading,
      btnTemplate: state => state.button.template,
      events: state => state.button.events
    }),
    ...mapGetters([
      'translate',
      'getFormElement',
      'getLayoutVariable',
      'verticalModalButtons'
    ]),
    hasOptionalContent() {
      return this.optionalContent?.length > 0
    },
    dynamicClasses() {
      return {
        wrapper: {
          'kr-simple-modal-wrapper--opening': this.animation.open.active,
          'kr-simple-modal-wrapper--closing': this.animation.close.active
        },
        verticalButtons: {
          'kr-simple-modal-footer--vertical': this.verticalModalButtons
        }
      }
    }
  },
  watch: {
    loading(val) {
      if (!isBoolean(val)) return
      const event = val ? 'showSpinner' : 'hideSpinner'
      if (isFunction(this.events[event]))
        this.events[event](this.$el.querySelector('.kr-btn-action'))
    }
  },
  created() {
    this.registerEventListeners()
  },
  methods: {
    ...mapActions(['closeModal', 'startModalLoading', 'callModalActions']),
    registerEventListeners() {
      const escapeHandler = e => {
        if ((e.key === `Escape` || e.key === 'Esc') && this.isOpen) {
          onAction('closeModal', 'close')
        }
      }

      document.addEventListener('keyup', escapeHandler)
      this.$once('hook:destroyed', () => {
        document.removeEventListener('keyup', escapeHandler)
      })
    },
    resolveVariables(base, variables) {
      if (variables) {
        for (const key in variables) {
          const variable = variables[key]
          const value = this.getLayoutVariable(
            variable,
            this.modalData.paymentMethodToken
          )
          base = base.replace(`[${key}]`, value)
        }
      }
      return base
    },
    contentHtml(content) {
      const base = this.translate(content.label)
      return this.resolveVariables(base, content.variables)
    },
    optionalLabelHtml(optional) {
      const base = this.translate(optional.label)
      return this.resolveVariables(base, optional.variables)
    },
    optionalLinkHtml(optional) {
      const base = this.translate(optional.link.label)
      return this.resolveVariables(base, optional.variables)
    },
    optionalLinkHref(optional) {
      let base = optional.link.href
      const matches = base.match(/{{{0,2}\s*([^\s{}]+)\s*}}{0,2}/g)
      if (matches) {
        for (const match of matches) {
          const transKey = match.replace('{{', '').replace('}}', '')
          // Get the translation key and format spaces and new lines
          const translation = this.translate(transKey)
            .replace(/ /g, '%20')
            .replace(/\n/g, '%0D%0A')
          base = base.replace(match, translation)
        }
      }

      return this.resolveVariables(base, optional.variables)
    },
    buttonHtml(action, label) {
      const translatedLabel = this.translate(label)
      if (action === 'closeModal') return translatedLabel

      return this.btnTemplate
        .replace('{price}', '')
        .replace('{label}', translatedLabel)
    },
    onAction(actions, name = undefined, loading = false) {
      if (this.isOpen && this.loading) return

      if (loading) this.startModalLoading()

      this.callModalActions({ actions, name })
    }
  }
}
</script>
