<template lang="pug">  
  .kr-smart-form-embedded-header(:style="styles.wrapper" v-show="headerVisible")
    .kr-amount-wrapper( :class="{'kr-expanded-header': this.hasSplitPayments}", ref="amountWrapper")
      span.kr-amount-label(:style="styles.amount.label", ref="amountLabel") {{translate('amount')}}
      span.kr-amount-figure(:style="styles.amount.figure" ref="amountFigure") {{hasSplitPayments ? getAmountLabel(initialAmount) : amountLabel}}
    SplitPaymentPanel
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SplitPaymentPanel from '@/host/components/smartform/SplitPaymentPanel'
import {
  translateToParentEnd,
  translateVerticalAlign
} from '@/common/util/dom.js'

export default {
  name: 'SmartFormEmbeddedHeader',
  components: {
    SplitPaymentPanel
  },
  computed: {
    ...mapGetters(['translate', 'hasSplitPayments', 'getAmountLabel']),
    ...mapState(['amountLabel', 'initialAmount']),
    ...mapState({
      headerConfig: state => state.form.smartform.embedded.header
    }),
    headerVisible() {
      return this.headerConfig.visibility
    },
    styles() {
      const { amountWrapper, amountLabel, amountFigure } = this.$refs
      const styles = {
        wrapper: {
          borderBottom: this.headerConfig.borderBottom
        },
        amount: {
          label: {
            transform: this.hasSplitPayments
              ? translateVerticalAlign(amountWrapper, amountLabel)
              : ''
          },
          figure: {
            color: this.headerConfig.amount.figure.color,
            transform: this.hasSplitPayments
              ? translateToParentEnd(amountWrapper, amountFigure)
              : ''
          }
        }
      }

      return styles
    }
  }
}
</script>
