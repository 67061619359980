<template lang="pug">
  .kr-skeleton-wrapper(v-if="!hasValidToken && !isSmartFormPopin")
    .kr-skeleton-header.kr-skeleton
    .kr-skeleton-method-label.kr-skeleton(v-for="n in 4", :key="n")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Skeleton',
  props: {
    element: {
      type: String,
      default: 'smartForm'
    }
  },
  computed: {
    ...mapGetters(['isSmartFormPopin', 'hasValidToken'])
  }
}
</script>
