import Zepto from 'zepto-webpack'
import Events from '@/configuration/Events'
import { findSelectorElement } from '@/common/util/dom'
import { includes } from '@/common/util/array'
import { whenDefined } from '@/common/util/check'
import Button from '@/host/api/Button'
import Fields from '@/host/api/Fields'
import Wallet from '@/host/api/Wallet'
import Overlay from '@/host/api/Overlay'
import SmartForm from '@/host/api/SmartForm'
import { defer, isEmpty, delay, intersection } from 'underscore'
import { nextTick } from '@/common/util/async'

export class KR {
  constructor() {
    this.ready = false
    this._resetKR()
  }

  button = Button(this)
  fields = Fields(this)
  wallet = Wallet(this)
  overlay = Overlay(this)
  smartForm = SmartForm(this)

  post = this._asyncWrapper('post', (route, data) => {
    return this.api.router.post[route](route, data)
  })

  registerPlugin = this._wrapper('registerPlugin', plugin =>
    this._plugins.push(plugin)
  )
  registerBootstrap = this._wrapper('registerBootstrap', bootstrap =>
    this._bootstraps.push(bootstrap)
  )
  blenderBoot = this._asyncWrapper('blenderBoot', () => this.blender.boot())

  /**
   * Deprecated methods
   */

  _deprecatedMethod(event, busEvent) {
    return this._wrapper(event, callback => {
      if (typeof console !== undefined && console.warn)
        console.warn(
          `Calling a deprecated method: ${event}. you should use onFormReady instead.`
        )
      this.$bus.$on(busEvent, callback)
    })
  }

  onFormReadyListener = this._deprecatedMethod(
    'onFormReadyListener',
    Events.krypton.iframes.allReady
  )
  onFormCreateListener = this._deprecatedMethod(
    'onFormCreateListener',
    Events.krypton.form.ready
  )

  /**
   * Add callback on an event
   */

  _addCallback(event, isDevTools = false) {
    return this._asyncWrapper(event, async callback => {
      await this.callbackHandler.addCallback(event, callback)
      if (!!this?.[`_${event}`]) await callback()
      if (isDevTools) {
        this.logger.log(
          'warning',
          `${event} system is for debug purpose only. Do not rely on it for your development. Changes will be done without any notice !`
        )
      }
    })
  }

  onFormReady = this._addCallback('onFormReady')
  onLoaded = this._addCallback('onLoaded')
  onFormCreated = this._addCallback('onFormCreated')
  onLog = this._addCallback('onLog', true)
  onBrandChanged = this._addCallback('onBrandChanged')
  onSubmit = this._addCallback('onSubmit')
  onTransactionCreated = this._addCallback('onTransactionCreated')
  on3dSecureAbort = this._addCallback('on3dSecureAbort')
  onFocus = this._addCallback('onFocus')
  onBlur = this._addCallback('onBlur') // KJS-1945
  onError = this._addCallback('onError')
  onPopinClosed = this._addCallback('onPopinClosed')
  onInstallmentChanged = this._addCallback('onInstallmentChanged')
  onFormValid = this._addCallback('onFormValid')
  onPaymentMethodSelected = this._addCallback('onPaymentMethodSelected')

  /**
   * Remove callback on an event
   */
  removeEventCallbacks = this._asyncWrapper('removeEventCallbacks', callback =>
    this.callbackHandler.removeEventCallbacks(callback)
  )

  /**
   * Configuration actions
   */
  setFormConfig = this._asyncWrapper('setFormConfig', async configObj => {
    if (configObj.hasOwnProperty('kr-form-token')) {
      if (configObj.hasOwnProperty('formToken')) {
        /**
         * Warning panel will be erased once token is set below
         * Hence must wait for next tick afterward before triggering warning
         */
        this.$bus.$once(Events.krypton.toolbar.events.cleaned, () => {
          defer(() => {
            this.$store.dispatch('warning', {
              errorCode: 'CLIENT_710',
              errorMessage: `KR.setFormConfig(): %translation%`
            })
          })
        })
      } else {
        configObj['formToken'] = configObj['kr-form-token']
      }
      delete configObj['kr-form-token']
    }

    if (configObj.hasOwnProperty('formToken')) {
      await this.setFormToken(configObj['formToken'])
      delete configObj['formToken']
    }

    if (configObj.hasOwnProperty('cardForm')) {
      if (configObj.hasOwnProperty('form')) {
        Object.assign(configObj['form'], configObj['cardForm'])
      } else {
        configObj['form'] = configObj['cardForm']
      }

      delete configObj['cardForm']
    }

    if (Object.keys(configObj).length) {
      await this.api.router.post['/formConfig']('/formConfig', configObj)
    }
  })

  setFormToken = this._asyncWrapper('setFormToken', async formToken => {
    // undefined and empty string are accepted -> transformed into DEMO-TOKEN-TO-BE-REPLACED afterwards
    if (formToken !== undefined && formToken !== '' && !formToken)
      return Promise.reject()

    formToken = this.tokenReader.verifyToken(formToken)
    formToken = this.tokenReader.setToken(formToken)

    // TODO: the alert cleanup shouldn't be here
    const $alertPopin = document.querySelector('.kr-alert-popin')
    if ($alertPopin) Zepto($alertPopin).remove()
    // Log form token update
    this.logger.log('info', `formToken updated`, { formToken })
    await this.blender.boot()
  })

  setShopName = this._wrapper('setShopName', shopName => {
    this.$store.dispatch('update', { shopName })
  })

  setHelpVisibility = this._wrapper('setHelpVisibility', (_, visibility) => {
    this.$bus.$emit(Events.krypton.form.help.visibility, { visibility })
  })

  setBrand = this._wrapper('setBrand', brand => {
    if (!brand) brand = 'auto'
    this.$store.dispatch('update', { forcedBrand: brand.toUpperCase() })
  })

  /**
   * Form management
   */
  _formAction(event, callback) {
    return this._asyncWrapper(event, async (selector, formType = 'cards') => {
      const $element = findSelectorElement(selector)
      if (!$element) {
        return Promise.reject({
          error: `Element on the DOM not found by selector ${selector}`
        })
      } else if ($element.nodeName.toUpperCase() !== 'DIV') {
        return Promise.reject({
          error: `Only 'div' is an acceptable container for the new form`
        })
      }

      await callback($element, selector, formType)

      // Call boot
      await this.blender.boot(true)
      return Promise.resolve({ formId: this.$store.state.forms.main })
    })
  }

  addForm = this._formAction(
    'addForm',
    ($element, selector, formType = 'cards') => {
      // If the container has content, clean it and emit a warning
      if ($element.children.length) {
        this.$bus.$once(Events.krypton.iframes.allReady, () => {
          defer(() => {
            this.$store.dispatch('warning', {
              errorCode: 'CLIENT_706',
              metadata: { selector }
            })
          })
        })
      }

      // Cleanup previous apps
      this.formCleaner.resetDom()

      // Adds the form and add kr-embedded of kr-smart-form to wrapper
      let html = `<div class="kr-embedded kr-out-of-view-form">
        <div class="kr-pan"></div>
        <div class="kr-expiry"></div>
        <div class="kr-security-code"></div>
        <button class="kr-payment-button"></button>
        <div class="kr-form-error"></div>
      </div>`
      if (formType === 'all') html = `<div class="kr-smart-form"></div>`

      $element.innerHTML = html

      this.$store.dispatch('update', { renderMode: 'addForm' })

      return Promise.resolve()
    }
  )

  attachForm = this._formAction('attachForm', ($element, selector) => {
    // Deprecation warning
    console.warn(
      `The use of KR.attachForm() is deprecated, please use KR.renderElements() instead.`
    )

    // Container itself should not have kr-embedded or kr-smart-form class
    const classes = $element.className.split(' ') || []
    const formClasses = ['kr-embedded', 'kr-smart-form', 'kr-smart-button']
    if (intersection(formClasses, classes).length) {
      $element.textContent = ''
      delay(() => {
        this.$store.dispatch('warning', {
          errorCode: 'CLIENT_709',
          metadata: { selector }
        })
      }, 100)
      return Promise.reject()
    }

    // Adds the embedded if it's element is empty
    if (
      !$element.querySelector('.kr-embedded, .kr-smart-form, .kr-smart-button')
    ) {
      const $embedded = document.createElement('div')
      $embedded.className = 'kr-embedded'
      $element.appendChild($embedded)
    }

    this.$store.dispatch('update', { renderMode: 'attachForm' })
    return Promise.resolve()
  })

  renderElements = this._asyncWrapper(
    'renderElements',
    async ($elements = undefined, ...args) => {
      const { dispatch, getters, state } = this.$store
      if (getters.isFormRendered()) {
        dispatch('error', {
          errorCode: 'CLIENT_725',
          metadata: {
            console: true
          }
        })
        return false
      }

      // reset
      dispatch('cleanError')
      dispatch('update', { dom: { onlyTaggedElements: false } })
      // should only receive one or zero args
      if (args.length > 0) {
        dispatch('error', {
          errorCode: 'CLIENT_719',
          metadata: {
            console: true
          }
        })
        if (state.testKeys) this.$bus.$emit(Events.krypton.data.errorAlert)
        // Too many arguments found stop rendering process
        return false
      }

      if ($elements !== undefined) {
        // if we have elements validate them
        $elements = await dispatch('parseRenderElements', $elements)
        if (!$elements) return false
      }

      await this.blender.boot(true, $elements)
      return { formId: state.forms.main }
    }
  )

  showForm = this._wrapper('showForm', () => {
    this.$bus.$emit(Events.krypton.form.showForm)
  })

  hideForm = this._wrapper('hideForm', () => {
    this.$bus.$emit(Events.krypton.form.hideForm)
  })

  removeForms = this._wrapper('removeForms', () => {
    // If there is a boot in progress, skip the removeForms, it's not really necessary
    if (this.blender.bootInProgress) return
    this._resetKR()
    this.formCleaner.resetDom()
  })

  closePopin = this._wrapper('closePopin', () => {
    this.$bus.$emit(Events.krypton.popin.close)
  })

  openPopin = this._wrapper('openPopin', () => {
    this.$bus.$emit(Events.krypton.popin.open)
  })

  submit = this._wrapper('submit', () => {
    const { state } = this.$store
    const formId = state.forms[state.activeForm]
    this.$bus.$emit(Events.krypton.form.submit, { formId })
  })

  validate = this._wrapper('validate', () => {
    const { state } = this.$store
    const formId = state.forms[state.activeForm]
    this.$proxy.send(this.storeFactory.create('validate', { formId }))
  })

  async validateForm() {
    if (!this.ready) await this._meantimeResolve()
    this.logger.log('info', `KR.validateForm call`)
    return new Promise((resolve, reject) => {
      this.$bus.$once(
        Events.krypton.message.validationResponse,
        ({ error }) => {
          // Transform to error object (json)
          if (error && !isEmpty(error)) {
            error.doOnError = () => {
              this.$store.dispatch('error', error)
            }
            reject({ KR: this, result: error })
          } else {
            resolve({ KR: this, result: null })
          }
        }
      )

      const { state } = this.$store
      const formId = state.forms[state.activeForm]
      this.$proxy.send(
        this.storeFactory.create('validate', { formId, throwError: false })
      )
    })
  }

  // Receives a valid URL or null
  setBinUpdateNotificationUrl = this._wrapper(
    'setBinUpdateNotificationUrl',
    (url, data = {}, timeout = 15000) => {
      this.$store.dispatch('setBinUpdateNotification', {
        url,
        data,
        timeout
      })
    }
  )

  /**
   * SmartForm
   */
  openPaymentMethod = this._wrapper('openPaymentMethod', paymentMethod => {
    if (paymentMethod === undefined) {
      // Find the first method available in the DNA
      paymentMethod = this.$store.state.smartForm.dnaPaymentMethods[0]
    }

    if (!paymentMethod) this.$store.dispatch('error', 'CLIENT_503')
    else this.$store.dispatch('openMethod', paymentMethod.toUpperCase())

    if (this.$store.state.testKeys && paymentMethod === 'APPLE_PAY') {
      this.$store.dispatch('warning', {
        errorCode: 'CLIENT_600',
        metadata: {
          console: true
        }
      })
    }
  })

  openSelectedPaymentMethod = this._wrapper('openSelectedPaymentMethod', () => {
    this.$store.dispatch('openSelectedMethod')
  })

  getPaymentMethods = async () => {
    if (!this.ready) await this._meantimeResolve()

    const { dnaCardBrands, dnaPaymentMethods } = this.$store.state.smartForm
    const { isApplePayCompatible, hasCardMethodAvailable } = this.$store.getters

    let paymentMethods

    // Remove APPLE_PAY if not available for the device/environment
    if (!this.$store.state.applePay.testMode && !(await isApplePayCompatible()))
      paymentMethods = dnaPaymentMethods.filter(
        method => method !== 'APPLE_PAY'
      )
    else paymentMethods = dnaPaymentMethods

    const obj = {
      paymentMethods,
      cardBrands: hasCardMethodAvailable ? dnaCardBrands : []
    }

    return obj
  }

  throwCustomError = this._wrapper(
    'throwCustomError',
    (errorMessage, paymentMethod) => {
      this.$store.dispatch('error', {
        errorCode: 'CLIENT_312',
        errorMessage,
        paymentMethod
      })
    }
  )

  /**
   * Private methods
   */
  _translate(key, lang = null) {
    return this.$store.getters.translate(key, lang)
  }

  /**
   * Wrappers
   */
  _wrapper(event, callback) {
    return async (...args) => {
      if (!this.ready) await this._meantimeResolve()
      this.logger.log('info', `KR.${event} call`, { ...args })
      callback(...args)
      return Promise.resolve({ KR: this })
    }
  }

  _asyncWrapper(event, callback) {
    return async (...args) => {
      await nextTick() // Wait for next tick in case there is something in progress
      if (!this.ready) await this._meantimeResolve()
      this.logger.log('info', `KR.${event} call`, { ...args })
      try {
        const result = await callback(...args)
        if (result) return Promise.resolve({ KR: this, result })
        return Promise.resolve({ KR: this })
      } catch (error) {
        this.logger.logPromiseError(error, `host/KR.${event}`)
        return Promise.reject(error)
      }
    }
  }

  /**
   * Waits until the library is loaded, to proceed
   */
  _meantimeResolve() {
    return new Promise((resolve, reject) => {
      whenDefined(window, 'KR', () => {
        whenDefined(window.KR, 'ready', () => {
          resolve()
        })
      })
    })
  }

  /**
   * Initiate all the required services and run the queue
   */
  _initKR($locator, $proxy, $bus) {
    this.reset = true
    this.toolbarReady = false
    this.tokenReader = $locator.tokenReader
    this.blender = $locator.blender
    this.storeFactory = $locator.storeFactory
    this.formCleaner = $locator.formCleaner
    this.api = $locator.api
    this.$store = $locator.$store
    this.logger = $locator.logger
    this.callbackHandler = $locator.callbackHandler
    this.$proxy = $proxy
    this.$bus = $bus
    this._resetKR()
    // Queue - execute again what has been called before KR was ready
    this.ready = true
  }

  /**
   * Set the initial state of flags and data
   */
  _resetKR(resetCallbacks = true) {
    this.loaded = false
    this.version = '%%library_version%%'
    this.buildNumber = parseInt('%%build_number%%', 10)
    this._waitingToken = null
    this._plugins = []
    this._bootstraps = []
    this._onLoaded = false
    this._onFormCreated = false
    this._onFormReady = false
    if (this.callbackHandler && resetCallbacks)
      this.callbackHandler.resetCallbacks()
  }
}
