import Events from '@/configuration/Events'
import { getApplePayFormattedAmount } from '@/common/service/CurrencyFormatter'
import { CoBrands } from '@/configuration/sources/Brands.yml'
import {
  brandFormat,
  brandCorrespondence
} from '@/configuration/sources/smartform/applePay.yml'

export default class ApplePayManager {
  constructor({ $store, $bus, proxy, storeFactory }) {
    this.$store = $store
    this.$bus = $bus
    this.proxy = proxy
    this.storeFactory = storeFactory
  }

  static canStartSession() {
    return typeof ApplePaySession === 'function'
  }

  /**
   * Initiates apple pay payment
   */
  initialize() {
    this.setupSession()
    this.setupListeners()
  }

  start() {
    this.session.begin()
  }

  static getSupportedNetworks(brands) {
    function transformToApplePayBrand(brand) {
      // LATAM brand variation to generic brand
      let formattedBrand = CoBrands.brands[brand] ?? brand

      // Specific changes of sub-brands must adapt to applePay supported brands
      formattedBrand = brandCorrespondence[formattedBrand] ?? formattedBrand

      // Adapt brand to the format used by ApplePay
      return brandFormat[formattedBrand]
    }
    const allowedBrands = [
      'visa',
      'masterCard',
      'amex',
      'discover',
      'electron',
      'cartesBancaires',
      'maestro'
    ]
    if (!brands?.length) return allowedBrands
    const applePayBrandsFromDna = brands
      .map(b => transformToApplePayBrand(b))
      .filter(b => !!b)

    return allowedBrands.filter(b => applePayBrandsFromDna.includes(b))
  }

  /**
   * Sets up the session using the API
   */
  setupSession() {
    const {
      amount,
      currency,
      language,
      country,
      shopName,
      smartForm: { dnaCardBrands }
    } = this.$store.state
    this.session = new ApplePaySession(3, {
      countryCode: country,
      currencyCode: currency,
      supportedNetworks: ApplePayManager.getSupportedNetworks(dnaCardBrands),
      merchantCapabilities: ['supports3DS'],
      total: {
        label: shopName,
        amount: getApplePayFormattedAmount(amount, currency, language)
      }
    })
  }

  /**
   * Sets up the listeners for the whole payment workflow
   */
  setupListeners() {
    this.onCancel()
    this.onValidate()
    this.onAuthorized()
    this.onError()
    // For the non-apple devices version
    if (typeof ApplePaySDK !== 'undefined') this.onModalClose()
  }

  cancelCallBack(event) {
    const error = { errorCode: 'CLIENT_101', paymentMethod: 'APPLE_PAY' }
    this.$store.dispatch('error', error) // Throw an abort error
    this.$store.dispatch('applePayRealModalClosed')
  }

  onCancel() {
    this.session.oncancel = this.cancelCallBack.bind(this)
  }

  // Non apple device modal with the QR code
  onModalClose() {
    // The applePay SDK (at least the beta) doesn't provide a event for the modal closing
    // We cant detect the user interaction as the modal closes automatically after a set timeout
    // Detect iframe element being removed from apple-pay-modal instead

    const iframeContainer = document
      .querySelector('apple-pay-modal')
      .shadowRoot.querySelector('.modal-content-container')

    const observer = new MutationObserver(mutationList => {
      // Check that a detected mutation has an IFRAME in the removedNodes list
      const isIframeRemoved = mutationList.some(e =>
        Array.from(e.removedNodes).some(e => e.tagName === 'IFRAME')
      )

      // Cancel callback if so
      if (isIframeRemoved) this.cancelCallBack()
    })

    // Observe iframeContainer and return events providing changed childLists
    observer.observe(iframeContainer, { childList: true })
  }

  onValidate() {
    this.session.onvalidatemerchant = ({ validationURL }) => {
      // Setup the applePay session creation listener
      this.$bus.$once(
        Events.krypton.message.applePayMerchantSession,
        ({ merchantSession }) => {
          this.session.completeMerchantValidation(merchantSession)
          this.$store.dispatch('applePayRealModalOpened')
        }
      )
      // Trigger the session creation
      this.proxy.send(
        this.storeFactory.create('getApplePayToken', {
          validationURL
        })
      )
    }
  }

  onAuthorized() {
    this.session.onpaymentauthorized = ({ payment }) => {
      // Listen to the payment event
      this.$bus.$once(
        [
          Events.krypton.message.payment,
          Events.krypton.message.applePayPaymentVerified
        ],
        msg => {
          // Trigger the successful payment on the API
          this.session.completePayment({
            status: ApplePaySession.STATUS_SUCCESS
          })
          this.$store.dispatch('applePayRealModalClosed')
        }
      )

      // Applepay payment will be triggered from the GetPayloadInfos or the extra fields modal
      this.proxy.send(
        this.storeFactory.create('getPayloadInfos', {
          payload: payment,
          payloadType: 'APPLE_PAY',
          testMode: false
        })
      )
    }
  }

  onError() {
    this.$bus.$once(Events.krypton.message.abortApplePaySession, () => {
      this.session.abort()
      this.$store.dispatch('applePayRealModalClosed')
    })
    this.$bus.$once(Events.krypton.message.applePayPaymentError, () => {
      this.session.completePayment({
        status: ApplePaySession.STATUS_SUCCESS
      })
      this.$store.dispatch('applePayRealModalClosed')
    })
  }
}
