<template lang="pug">
include templates/SmartButton.pug
+wrapper({ type: 'fallback'})
</template>

<script>
import { SmartButtonMixin } from '@/host/components/mixins/SmartButton'

export default {
  name: 'FallbackSmartButton',
  mixins: [SmartButtonMixin],
  data() {
    return {
      brands: '',
      brandFilter: ''
    }
  },
  computed: {
    paymentMethod() {
      return this.paymentMethods[0]
    },
    /**
     * It will be visible only when there is one
     * payment method declared in the DNA and it's not an integrated
     * cards form
     */
    isVisible() {
      return this.isFallbackSmartButtonVisible
    },
    isSelected() {
      return true
    },
    showAmount() {
      return !this.hasCustomLabelWithAmount
    },
    hasRegularLabel() {
      return this.cleanPaymentMethod !== 'CARDS' && !this.showLoadingAnimation
    },
    hasSingleBrand() {
      return false
    },
    hasCustomLabelWithAmount() {
      return false
    },
    singleBrandLabel() {
      return ''
    },
    methodLabel() {
      return this.defaultMethodLabel
    },
    regularMethodLabel() {
      return (
        this.getCustomPaymentMethodLabel(this.cleanPaymentMethod) ||
        this.methodLabel
      )
    },

    displayedBrands() {
      return this.cardBrands
    }
  }
}
</script>
