<template lang="pug">
  span.kr-method-badge(v-if="visible", :class="dynamicClasses", :title="title") {{ label }}
</template>

<script>
import { ApplePayMixin } from '@/host/components/mixins/ApplePay'
import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'
import { mapState } from 'vuex'

export default {
  name: 'SmartFormMethodBadge',
  mixins: [ApplePayMixin],
  props: {
    method: { type: String, default: '' },
    compact: { type: Boolean, default: false }
  },
  computed: {
    ...mapState({
      isGooglePaySimulatorConfigured: ({
        googlePay: { testMode, testModeWithoutSimulator }
      }) => testMode && !testModeWithoutSimulator
    }),
    visible() {
      return (
        this.applePaySimulator ||
        this.isSandBoxMethod ||
        this.isGooglePaySimulator
      )
    },
    label() {
      const label = this.isSandBoxMethod ? 'Sandbox' : 'Simulator'
      return this.compact ? '' : label
    },
    isSandBoxMethod() {
      return paymentMethodsConf.sandboxMethods.includes(this.method)
    },
    isGooglePaySimulator() {
      return this.method === 'GOOGLEPAY' && this.isGooglePaySimulatorConfigured
    },
    dynamicClasses() {
      return {
        'kr-method-badge--compact': this.compact
      }
    },
    title() {
      if (this.isGooglePaySimulator) return 'Google Pay Simulator'
      return this.isSandBoxMethod
        ? `${this.method} Sandbox`
        : 'Apple Pay Simulator'
    }
  }
}
</script>
