import GridConfigMixin from '@/host/components/mixins/grid/Config'

/**
 * Mixin for column computation
 */
export default {
  mixins: [GridConfigMixin],
  data() {
    return {
      forcedCompute: 0 // Helper to force recompute
    }
  },
  mounted() {
    // Force recompute on mounted
    this.onResize()
  },
  methods: {
    onResize() {
      this.forcedCompute++
    },
    computeColumns(width, gap) {
      if (!this.forcedCompute) return 1 // Not mounted yet - default to 1
      const maxCols = this.getMaxColumns(width, gap)

      if (this.columns === 'max' || this.columns > maxCols) return maxCols

      return this.groupMethods > this.columns ? this.columns : this.groupMethods
    },
    getMaxColumns(spaceAvailable, gap) {
      const spacePerCol = this.calculateMinSpacePerColumn(gap)

      return Math.floor(spaceAvailable / spacePerCol)
    },
    calculateMinSpacePerColumn(gap) {
      return parseInt(this.colMinWidth) + (parseInt(gap) || 0)
    }
  }
}
