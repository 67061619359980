<template lang="pug">
.kr-app-container(kr-resource)
  KryptonLayerManager(z-reference="10000", attach="body")
</template>

<script>
import Zepto from 'zepto-webpack'
import Events from '@/configuration/Events'
import _ from 'underscore'
import { mapGetters, mapState } from 'vuex'
import { eventSubscribe } from '@/host/EventSubscribe'
import { isLoadedLocally } from '@/common/util/url'
import { callWhenReady } from '@/common/util/check'
import { logSentryException } from '@/common/util/sentry'
import DialogScrollOverflow from '@/host/components/mixins/DialogScrollOverflow'
import KryptonLayerManager from '@/host/components/controls/KryptonLayerManager'

export default {
  name: 'HostApp',
  components: {
    KryptonLayerManager
  },
  mixins: [DialogScrollOverflow],
  data() {
    return {
      KR: {},
      flexModeMaxWidth: 860
    }
  },
  computed: {
    ...mapState([
      'allIFramesReady',
      'isUnitaryTest',
      'networkLoadTimeData',
      'browser',
      'os'
    ]),
    ...mapGetters(['translate', 'isIphone', 'isIpad'])
  },
  watch: {
    allIFramesReady(allIFramesReady) {
      this.KR._onFormReady = allIFramesReady
    }
  },
  created() {
    // Create the app
    this.createApplication()
    if (this.isUnitaryTest) return
    window.KR_CLIENT_LOADED = true
    this.startSubscriptions()
    this.startTrackingTime()
  },
  mounted() {
    this.tagFlexDetection()
    this.createIOSHelp()
  },
  methods: {
    async onReady() {
      try {
        await this.$locator.blender.boot(false, null, true)
        window.KR.loaded = true
        window.KR._onLoaded = true
        this.$bus.$emit(Events.krypton.form.loaded)
      } catch (error) {
        logSentryException(error, 'host/HostApp')
      }
    },
    tagFlexDetection() {
      // We have access to the DOM and view. We must check if the window size
      // is less or equal than 1024px and it is not IE10
      let tagAsFlex = true

      // It is needed a tablet or mobile resolution to be able to use flex form
      let realWidth = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      )
      if (realWidth > this.flexModeMaxWidth) tagAsFlex = false

      // IE10 is removed from the browsers compatibles with flex form
      if (
        this.browser?.name == 'Internet Explorer' &&
        parseInt(this.browser?.version, 10) < 11
      )
        tagAsFlex = false

      if (tagAsFlex) this.$store.dispatch('update', { flexMode: true })
    },
    startSubscriptions() {
      // Event listener
      eventSubscribe(this.$locator, this.KR)

      Zepto(document).ready(this.onReady)

      if (!this.isUnitaryTest) {
        this.$bus.$once(Events.krypton.form.ready, message => {
          // Check if application is load locally
          this.raiseErrorLoadedLocally(window.location.href)
        })
      }

      this.$bus.$on(Events.krypton.form.ready, message => {
        // Call plugins bootstrap
        this.KR._bootstraps.forEach(bootstrap => {
          bootstrap(Zepto, this.$bus)
        })
      })

      this.$bus.$on(Events.krypton.form.showForm, () => {
        Zepto(`.kr-embedded, .kr-smart-form`).removeClass('kr-out-of-view-form')
      })

      this.$bus.$on(Events.krypton.form.hideForm, () => {
        Zepto(`.kr-embedded, .kr-smart-form`).addClass('kr-out-of-view-form')
      })
    },
    startTrackingTime() {
      // Cut the number to 3 decimals
      const round = number => {
        return number.toFixed(0)
      }

      if (performance?.now && window) {
        let t0 = performance.now()
        this.$store.dispatch('update', { timeStart: t0 })

        // Network tracking time
        // Get a list of "resource" performance entries
        if (_.isFunction(performance.getEntriesByType)) {
          const resources = performance.getEntriesByType('resource')
          let networkLoadTimeData = []
          if (typeof resources !== 'undefined' && resources.length) {
            for (let r = 0; r < resources.length; r++) {
              const res = resources[r]
              if (res.name.indexOf('kr-payment-form.min.js') === -1) continue

              // Save the steps data
              networkLoadTimeData.push({
                detail: 'DNS lookup',
                time: round(res.domainLookupEnd - res.domainLookupStart)
              })
              networkLoadTimeData.push({
                detail: 'TCP connection (including SSL)',
                time: round(res.connectEnd - res.connectStart)
              })
              networkLoadTimeData.push({
                detail: 'SSL only',
                time: round(
                  res.secureConnectionStart > 0
                    ? res.connectEnd - res.secureConnectionStart
                    : 0
                )
              })
              networkLoadTimeData.push({
                detail: 'Waiting server response',
                time: round(
                  res.requestStart > 0
                    ? res.responseStart - res.requestStart
                    : 0
                )
              })
              networkLoadTimeData.push({
                detail: 'Download time',
                time: round(res.responseEnd - res.responseStart)
              })
              networkLoadTimeData.push({
                detail: 'Other',
                time: 0
              })
              networkLoadTimeData.push({
                detail: 'Total',
                time: round(
                  res.startTime > 0 ? res.responseEnd - res.startTime : 0
                ),
                total: true
              })
              break
            }
          }
          this.$store.dispatch('update', { networkLoadTimeData })
        }
      }
    },
    createIOSHelp() {
      const $bus = this.$bus
      const $store = this.$store
      // Add an input to capture the focus on iOS
      if (this.isIphone || this.isIpad) {
        Zepto('.kr-payment-button').before(
          '<input type="radio" style="position:relative;display:block;top: 0;left: 0;width: 0px;height: 0px;background-color: transparent;border: 0px solid transparent;padding: 1px;" class="kr-focus-hunter" aria-label="focus"/>'
        )
        let focusedIframeField

        const fixFocus = e => {
          focusedIframeField = undefined
          $store.dispatch('forceFieldBlur')
        }

        setInterval(() => {
          // Listen to input focus to avoid the Webkit bug
          Zepto('input').off(
            'focus touchstart touchend focusin mousedown mouseup',
            fixFocus
          )
          Zepto('input').on(
            'focus touchstart touchend focusin mousedown mouseup',
            fixFocus
          )
        }, 1000)

        setInterval(() => {
          if (focusedIframeField) {
            let activeElementType = document.activeElement.tagName
            let i = 0
            while (activeElementType === 'INPUT' && i < 10) {
              document.activeElement.blur()
              activeElementType = document.activeElement.tagName
              i++
            }
          }
        }, 250)

        $bus.$on(Events.krypton.message.fieldEvent, message => {
          if (message.type === 'triggerFocusActions') {
            focusedIframeField = message.name
          }
        })
      }
    },
    createApplication() {
      window.KR._initKR(this.$locator, this.$proxy, this.$bus)
      this.KR = window.KR

      const $bus = this.$locator.$bus
      $bus.$on(Events.krypton.form.ready, () => {
        this.KR._onFormCreated = true
      })

      if (window.kr_loader) {
        window.kr_loader.registeredBootstraps.forEach(bootstrap => {
          bootstrap()
        })
      }

      // Enable the communication with the iframes
      this.$proxy.enableCommunicator('host')

      // Check if kr-when-ready is defined and call it
      callWhenReady()
    },
    raiseErrorLoadedLocally(URL) {
      if (isLoadedLocally(URL)) {
        console.error(this.translate('CLIENT_711'))
        this.$store.dispatch('disableForm')
        this.$store.dispatch('error', 'CLIENT_711')
      }
    }
  }
}
</script>
