import { extend } from 'underscore'
import payment from '@/common/util/payment'
import AbstractTrigger from '@/ghost/service/Triggers/AbstractTrigger'

export default class TokenPaymentTrigger extends AbstractTrigger {
  constructor($locator) {
    super($locator, 'payWithToken')
    this.browserRequests = $locator.browserRequests
    this.$store = $locator.$store
  }

  /**
   * Token Payment action
   *
   * @method onEvent
   * @param action payWithToken action
   */
  async onEvent(action) {
    this.formId = action.formId
    try {
      await super.onEvent(action)
      const { formToken, publicKey, remoteId } = this.$store.state
      const { method, paymentMethodToken } = action

      const url = this.restAPI.addJSessionID(
        this.restAPI.getProcessPaymentUrl(this.endpoint)
      )

      const request = this.storeFactory.create('requestData', {
        url,
        objectData: {
          formToken,
          publicKey,
          paymentForm: {
            method,
            paymentMethodToken
          },
          remoteId,
          clientVersion: this.restAPI.getClientVersion(),
          device: this.restAPI.getDeviceData(),
          wsUser: this.restAPI.getWSUser()
        },
        headers: {},
        options: {}
      })

      const { response } = await this.browserRequests.post(request)

      if (response.status === 'ERROR') {
        this.onError(response.answer)
      } else {
        this.processResponse(response)
      }

      return Promise.resolve()
    } catch (error) {
      this.onError(error)
    }
  }

  async onError(error, path = 'ghost/service/TokenPaymentTrigger.onEvent') {
    this.$store.dispatch('closeModal')
    super.onError(error, path)
  }

  /**
   * Process the server response
   */
  processResponse(payResp) {
    const answer = payResp.answer
    const status = answer?.clientAnswer?.transactions[0]?.status.toUpperCase()
    const prRegex = /^V\d{1}.*\/Charge\/ProcessPaymentAnswer$/

    // Payment accepted or running
    if (~['PAID', 'RUNNING'].indexOf(status) && prRegex.test(answer._type)) {
      const transStore = this.storeFactory.create(
        'billingTransaction',
        extend(answer, {
          rawClientAnswer: JSON.stringify(answer.clientAnswer)
        })
      )

      payment.handler(transStore.json(), this.$locator)
    } else {
      const error = {}
      if (status === 'UNPAID') error.metadata = { answer }
      throw error
    }
  }
}
