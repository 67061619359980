var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasSplitPayments
    ? _c(
        "div",
        {
          directives: [
            {
              name: "onresize",
              rawName: "v-onresize",
              value: _vm.onResize,
              expression: "onResize",
            },
          ],
          staticClass: "kr-split-payment-panel",
        },
        [
          _c("div", { staticClass: "kr-split-payment-label" }, [
            _vm._v(_vm._s(_vm.translate("split_payment_label"))),
          ]),
          _c(
            "TransitionGroup",
            {
              staticClass: "kr-split-payments",
              attrs: { name: "test", tag: "div" },
            },
            _vm._l(_vm.transactions, function (ref, i) {
              var amount = ref.amount
              var brand = ref.brand
              var pan = ref.pan
              var uuid = ref.uuid
              return _c(
                "div",
                { key: i, staticClass: "kr-split-payment" },
                [
                  _c(
                    "div",
                    { staticClass: "kr-split-payment-remove-icon-container" },
                    [
                      _c("button", {
                        staticClass: "kr-split-payment-remove-icon",
                        attrs: { type: "button" },
                        domProps: { innerHTML: _vm._s(_vm.removeIcon) },
                        on: {
                          click: function ($event) {
                            return _vm.cancelSplitPayment(uuid)
                          },
                        },
                      }),
                    ]
                  ),
                  _c("BrandIcon", { attrs: { method: brand } }),
                  _c("SmartFormMaskedCardLabel", { attrs: { value: pan } }),
                  _c("span", { staticClass: "kr-split-payment-amount" }, [
                    _vm._v(_vm._s(_vm.getAmountLabel(amount))),
                  ]),
                ],
                1
              )
            }),
            0
          ),
          _c("div", { staticClass: "kr-split-payment-remaining" }, [
            _c("span", { staticClass: "kr-split-payment-remaining-label" }, [
              _vm._v(_vm._s(_vm.translate("split_payment_left"))),
            ]),
            _c("span", { staticClass: "kr-split-payment-remaining-amount" }, [
              _vm._v(_vm._s(_vm.getAmountLabel(_vm.pendingAmount))),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }