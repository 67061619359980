import Zepto from 'zepto-webpack'
import Modal from '@/configuration/sources/SimpleModal.yml'
import modalStyles from '@/configuration/sources/ModalStyles.yml'
import { getPathValue } from '@/common/util/object'
import Events from '@/configuration/Events'

export const getPopinState = () => {
  return {
    isPopinOpen: false,
    modal: {
      isClosing: false,
      isOpen: false,
      loading: false,
      layout: {
        title: '',
        content: [],
        optional: [],
        buttons: [],
        options: []
      },
      data: {},
      triggers: {
        postClose: {
          action: undefined,
          params: undefined
        }
      },
      selectedOption: null
    },
    // KryptonDialog component
    // list of currently open dialogs, used to check isAnyDialogOpen getter
    dialog: {
      activeDialogs: []
    }
  }
}

export const popinActions = ($locator, app) => {
  return {
    openPopin({ commit, state, getters, dispatch }) {
      if (getters.isFormPopin && !state.isPopinOpen) {
        dispatch('cleanError')
        commit('UPDATE', { isPopinOpen: true })
        Zepto(`.kr-embedded`)
          .addClass('kr-popin-open')
          .parent()
          .css(modalStyles.popin.opened)
      }
    },
    closePopin({ dispatch, commit, getters }) {
      const { isAnyPopinOpen } = getters
      if (isAnyPopinOpen) {
        commit('UPDATE', { isPopinOpen: false })
        Zepto(`.kr-embedded`)
          .removeClass('kr-popin-open')
          .parent()
          .css(modalStyles.popin.closed)
        dispatch('closeSmartFormPopin')
      }
    },
    openModal({ commit, state, getters }, { layout, ...data }) {
      // Reset Layout and selectedOption
      commit('UPDATE', {
        modal: { layout: getPopinState().modal.layout, selectedOption: null }
      })

      // Build layout partials
      Object.entries(layout).map(([key, value]) => {
        if (value.partial) {
          const partial = Modal.partials[value.partial]
          if (partial) layout[key] = partial
        }
      })

      // Check conditions for optional fields
      const { getLayoutVariable } = getters
      if (Array.isArray(layout.optional) && layout.optional.length > 0) {
        layout.optional = layout.optional.filter(optional => {
          const condition = optional.condition
          return getLayoutVariable(condition, data.paymentMethodToken)
        })
      }

      commit('UPDATE', {
        modal: { isOpen: true, layout, data }
      })
    },
    // Returns a promise that gets resolved once the modal is closed.
    openAwaitableModal(
      {
        dispatch,
        state: {
          modal: { selectedOption }
        }
      },
      { layout, ...data }
    ) {
      return new Promise(resolve => {
        $locator.$bus.$once(Events.krypton.message.closeSimpleModal, () =>
          resolve(selectedOption)
        )
        dispatch('openModal', { layout, ...data })
      })
    },
    /**
     * Starts the closing modal process, there is a delay (for the animation) until the modal really closes
     *
     * postCloseAction and props are OPTIONAL and get called after the modal completely ends the closing animation.
     * The action must be a store action, it cannot be a callback function as it may be called from the ghost and will not be properly synchronized
     */
    closeModal({ commit, getters, dispatch }, params = {}) {
      const { postCloseAction, postCloseActionProps } = params

      const { proxy, storeFactory } = $locator
      proxy.send(storeFactory.create('closeSimpleModal'))

      commit('UPDATE', {
        modal: {
          isClosing: true,
          isOpen: false,
          loading: false,
          triggers: {
            postClose: {
              action: postCloseAction,
              props: postCloseActionProps
            }
          }
        }
      })
      if (getters.popinOpensMethodDirectly) {
        dispatch('error', getters.getAbortedErrorCode)
      }
    },
    startModalLoading({ commit }) {
      commit('UPDATE', { modal: { loading: true } })
    },
    stopModalLoading({ commit }) {
      commit('UPDATE', { modal: { loading: false } })
    },
    finishModalClosing({ commit, state, dispatch }) {
      const { action, props } = state.modal.triggers.postClose

      // Call the saved action
      if (action) dispatch(action, props)

      // Reset the action for any following modal windows
      commit('UPDATE', {
        modal: {
          triggers: {
            postClose: {
              action: undefined,
              props: undefined
            }
          }
        }
      })
    },
    addDialogToActiveList({ commit, state }, component_uid) {
      const updated = [...state.dialog.activeDialogs, component_uid]

      commit('UPDATE', { dialog: { activeDialogs: updated } })
    },
    removeDialogFromActiveList({ commit, state }, component_uid) {
      const updated = state.dialog.activeDialogs.filter(
        comp_uid => component_uid !== comp_uid
      )
      commit('UPDATE', { dialog: { activeDialogs: updated } })
    },
    callModalActions({ commit, state, dispatch }, { actions, name }) {
      commit('UPDATE', { modal: { selectedOption: name } })

      if (!Array.isArray(actions)) actions = [actions]
      actions.forEach(action => dispatch(action, state.modal.data))
    }
  }
}

export const popinMutations = {}

export const popinGetters = {
  isFormPopin: state => state.formType === 'popin',
  isAnyPopinOpen: state =>
    (state.formType === 'popin' && state.isPopinOpen) ||
    (state.formWidget === 'smartForm' && state.smartForm.isOpen),
  isCardsFormPopin: (state, { isSmartForm, isFormPopin }) =>
    !isSmartForm && isFormPopin,
  isAnyDialogOpen: state => state.dialog.activeDialogs.length !== 0,
  isModalOpen: state => state.modal.isOpen,
  getLayoutVariable:
    ({ dna }, { getToken }) =>
    (variable, token) => {
      if (variable?.startsWith('$token')) {
        const path = variable.replace('$token.', '')
        const tokenConf = getToken(token)
        return getPathValue(tokenConf, path)
      }
      if (variable?.startsWith('$dna')) {
        const path = variable.replace('$dna.', '')
        return getPathValue(dna, path)
      }
    },
  verticalModalButtons: ({ modal }) =>
    !!modal.layout.options?.find(option => option?.verticalButtons)
      ?.verticalButtons
}
