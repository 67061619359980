import Events from '@/configuration/Events'
import { render } from '@/common/service/TemplateCompiler'
import { isFunction } from 'underscore'
import { mapGetters, mapState } from 'vuex'
import { dynamicMapState, dynamicMapActions } from '@/common/util/store'

/** MIXINS */
import ConfigurationMixin from '@/host/components/mixins/Configuration'

export default {
  mixins: [ConfigurationMixin],
  inject: ['context'],
  data() {
    return {
      value: this.$options.$field.getAttribute('value') || '',
      required: this.$options.$field.getAttribute('required') !== null,
      fieldName: this.$options.$field.getAttribute('name'),
      isFocused: false,
      $input: null
    }
  },
  computed: {
    ...mapState(['redirectIframeOpen', 'processingPayment']),
    ...mapGetters(['isCardFormVisible']),
    ...mapState({
      events: state => state.fields.custom.events,
      formError: state => state.error
    }),
    ...dynamicMapState('context.namespace', ['fieldsOrder'])
  },
  watch: {
    redirectIframeOpen(val) {
      if (val && this.isCardFormVisible(this.context.formId))
        Zepto(this.$el).hide()
      else if (val) Zepto(this.$el).show()
    },
    formError: {
      deep: true,
      handler(error) {
        const { children, errorCode, field } = error
        if (field === this.fieldName)
          this.isInvalid = errorCode === 'CLIENT_304'
        else
          this.isInvalid = children.reduce(
            (acc, x) =>
              acc ||
              (x.field === this.fieldName && x.errorCode === 'CLIENT_304'),
            false
          )
      }
    }
  },
  mounted() {
    this.setInput()

    // Call the mounted callback
    this.$nextTick(() => {
      this.handler('Mounted')
      this.isVisible = true
    })
    // Tab control
    this.$busOn(Events.krypton.field.focus, ({ name, formId }) => {
      if (name === this.fieldName && formId === this.context.formId)
        this.focusHandler()
    })

    this.$input.addEventListener('keyup', this.tabHandler)
    this.$input.addEventListener('keydown', this.tabHandler)
  },
  methods: {
    ...dynamicMapActions('context.namespace', ['inviableField', 'viableField']),
    setInput() {
      this.$input = this.$el.querySelector('input')
      this[`${this.required ? 'in' : ''}viableField`](this.fieldName)
    },
    handler(type) {
      const callback = 'on' + type
      if (this.events && isFunction(this.events[callback])) {
        this.events[callback](this.fieldName, Zepto(this.$el).find('input'), $)
      }
      if (this[callback]) this[callback]
    },
    focusHandler() {
      this.isFocused = true
      this.$input.focus()
      this.handler('Focus')
    },
    blurHandler() {
      this.isFocused = false
      this.isInvalid = this.required && !this.value
      this.$input.blur()
      this.handler('Blur')
    },
    tabHandler(e) {
      if (e.key.toLowerCase() === 'tab') {
        e.preventDefault()

        if (e.type === 'keydown') {
          this.$bus.$emit(Events.krypton.field.event, {
            formId: this.context.formId,
            type: e.shiftKey ? 'reverseTab' : 'tab',
            name: this.fieldName
          })
          this.blurHandler()
        }
      }
    },
    inputHandler(e) {
      this.handler('Input')
      const value = e.target.value
      this[`${this.required && !value ? 'in' : ''}viableField`](this.fieldName)
    },
    getCustomDomElement() {
      let template = this.defaultTemplate

      // If there custom template, set it
      if (this.customTemplate) template = this.customTemplate

      // Replace slot
      template = template.replace('{slot}', '$SLOT')
      template = template.replace('@slot', '$SLOT')

      // Perform replacements
      const replacements = template.match(/(\{attribute\.[^\}]+\})/g) || []
      template = template.replace(/@if=/g, 'v-if=')
      template = template.replace(/@show=/g, 'v-show=')
      template = template.replace(/@class=/g, 'v-bind:class=')
      for (const replacement of replacements) {
        const cleanReplacement = replacement
          .replace('{attribute.', '')
          .replace('}', '')
        const value = this.$options.$field.getAttribute(cleanReplacement)
        const regex = new RegExp(replacement, 'g')
        template = template.replace(regex, value ? value : '')
      }

      // Inject slot
      const div = document.createElement('div')
      div.appendChild(this.$options.$field.cloneNode(true))
      template = template.replace('$SLOT', `${div.innerHTML}`)
      template = template.replace(
        /\<input\s/g,
        '<input v-on:focus="focusHandler" v-on:blur="blurHandler" v-on:input="inputHandler" v-model="value" ref="input" '
      )

      return Zepto(template)
    }
  },
  render(createElement) {
    const element = this.getCustomDomElement()
    return render(this, createElement, element)
  }
}
