import AbstractTrigger from '@/ghost/service/Triggers/AbstractTrigger'

export default class DeclinePartialPaymentTrigger extends AbstractTrigger {
  constructor($locator) {
    super($locator, 'declinePartialPaymentCall')
    this.browserRequests = $locator.browserRequests
  }

  async onEvent({ transactionUuid }) {
    try {
      await super.onEvent({ transactionUuid })
      const { response } = await this.callDeclinePartialPayment(transactionUuid)
      if (response.status === 'ERROR') {
        this.onError(response.answer)
      } else {
        this.proxy.send(
          this.storeFactory.create('declinePartialPaymentApply', {
            transactionUuid,
            clientAnswer: response.answer.clientAnswer
          })
        )
      }
    } catch (error) {
      this.onError(error)
    }
  }

  async onError(
    error,
    path = 'ghost/service/DeclinePartialPaymentTrigger.onEvent'
  ) {
    super.onError(error, path)
  }

  async callDeclinePartialPayment(transactionUuid) {
    const { formToken, publicKey } = this.$store.state
    const requestData = this.storeFactory.create('requestData', {
      url: this.restAPI.getDeclinePartialPaymentUrl(this.endpoint),
      objectData: {
        formToken,
        publicKey,
        wsUser: this.restAPI.getWSUser(),
        transactionUuid
      },
      headers: {},
      options: {}
    })
    return await this.browserRequests.post(requestData)
  }
}
