<template lang="pug">
  .kr-popin-redirection(v-show="redirectIframeOpen && !isSmartForm")
    .kr-background
    .kr-popin-redirection-modal(:class="dynamicClasses.modal")
      KryptonPopinHeader(v-on:close="closeModal()")
      KryptonGhostContainer
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import KryptonPopinHeader from '@/host/components/popin/KryptonPopinHeader'
import KryptonGhostContainer from '@/host/components/KryptonGhostContainer'

export default {
  name: 'KryptonPopinRedirection',
  components: {
    KryptonPopinHeader,
    KryptonGhostContainer
  },
  computed: {
    ...mapGetters([
      'isSmallScreen',
      'isSmartForm',
      'hasDeadEndPaymentMethod',
      'abortedErrorCode'
    ]),
    ...mapState(['redirectIframeOpen', 'redirectTransaction', 'receiptOpen']),
    dynamicClasses() {
      return {
        modal: {
          'kr-popin-redirection-modal--mobile': this.isSmallScreen
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'closePopin',
      'forceFieldClear',
      'error',
      'finishRedirection',
      'closePopin'
    ]),
    closeModal() {
      if (this.receiptOpen) {
        this.$proxy.send(this.$storeFactory.create('closeReceipt', {}))
        return
      }
      if (!this.hasDeadEndPaymentMethod() && !this.redirectTransaction)
        this.error(this.abortedErrorCode)

      this.finishRedirection(true)
      this.closePopin()

      const $ghost = this.$locator.iframeController.ghostContainer
      $ghost.hide()

      // Report by event the closing action of the popin
      this.forceFieldClear()
    }
  }
}
</script>
