import { loadScriptPromise } from '@/common/util/dom'
import GooglePayConfig from '@/configuration/sources/smartform/googlePay.yml'
import GooglePayManager from '@/ghost/service/GooglePayManager'
import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'
import { getGooglePayFormattedAmount } from '@/common/service/CurrencyFormatter'
import { isInsecureEnvironment } from '@/common/util/environment'

export const getGooglePayState = () => {
  return {
    googlePay: {
      ready: false,
      testMode: false,
      simulator: {
        open: false
      },
      testModeWithoutSimulator: false
    }
  }
}

export const googlePayActions = ($locator, app) => {
  return {
    // Called from host
    async setupGooglePayHost({
      state: { isUnitaryTest },
      getters,
      commit,
      dispatch
    }) {
      if (getters.isGooglePaySimulator) {
        if (isUnitaryTest) return

        const module = await import(
          /* webpackChunkName: "kr-simulator-google-pay" */ '@/simulator/googlepay/Loader'
        )
        const loader = module.default
        loader($locator)
        commit('UPDATE', { googlePay: { ready: true, testMode: true } })
      } else {
        if (!getters.isGooglePayCompatible) {
          dispatch('removePaymentMethod', 'GOOGLEPAY')
          dispatch('removeDnaPaymentMethod', 'GOOGLEPAY')
        }
      }
    },
    // Called from ghost
    async setupGooglePaySDK({
      state: { testKeys, isUnitaryTest },
      getters,
      commit,
      dispatch
    }) {
      if (!getters.isGooglePaySimulator) {
        if (isInsecureEnvironment() || isUnitaryTest) return

        await loadScriptPromise(
          paymentMethodsConf.sources.GOOGLEPAY,
          'kr-google-pay'
        )
        const isReadyResult = await GooglePayManager.isReadyToPayCall({
          testMode: testKeys,
          cvcRequired: false,
          dnaBrands: getters.getGooglePayBrands
        })?.catch(error => ({ error, result: false }))

        if (!isReadyResult?.result) {
          dispatch('removePaymentMethod', 'GOOGLEPAY')
          dispatch('removeDnaPaymentMethod', 'GOOGLEPAY')
          // todo sentry reporting maybe
          if (isReadyResult.error) console.log(isReadyResult.error)
          return
        }

        commit('UPDATE', { googlePay: { ready: true, testMode: testKeys } })
      }
    },
    startGooglePayPayment({ state, dispatch, commit, getters }) {
      if (isInsecureEnvironment() && !getters.isGooglePaySimulator) {
        dispatch('error', {
          errorCode: 'CLIENT_514',
          paymentMethod: 'GOOGLEPAY'
        })
        return
      }

      if (!state.googlePay.ready) return

      dispatch('cleanError')
      commit('UPDATE', { smartForm: { activeMethod: 'GOOGLEPAY' } })

      const { gateway, gatewayMerchantId, askCvv } =
        state.dna.smartForm?.GOOGLEPAY?.metadata || {}
      const { domain, protocol } = state.merchant
      const merchantOrigin = `${protocol}//${domain}`

      $locator.googlePayManager.start({
        merchantName: state.dna.shopName,
        merchantId: GooglePayConfig.merchantId,
        merchantOrigin,
        gateway,
        gatewayMerchantId,
        cvcRequired: askCvv,
        countryCode: state.country,
        currencyCode: state.currency,
        totalPrice: getGooglePayFormattedAmount(state.amount),
        dnaBrands: getters.getGooglePayBrands,
        testMode: state.googlePay.testMode,
        testModeWithoutSimulator: state.googlePay.testModeWithoutSimulator
      })
    },
    async activateTestModeWithoutSimulator({ commit, dispatch }) {
      commit('UPDATE', {
        googlePay: { testModeWithoutSimulator: true }
      })
      await dispatch('setupGooglePaySDK')
    },
    openGooglePaySimulator({ commit }) {
      commit('UPDATE', {
        googlePay: { simulator: { open: true } }
      })
    },
    closeGooglePaySimulator({ commit }) {
      commit('UPDATE', {
        googlePay: { simulator: { open: false } }
      })
    }
  }
}
export const googlePayMutations = {}
export const googlePayGetters = {
  isGooglePaySimulator: ({
    testKeys,
    googlePay: { testModeWithoutSimulator }
  }) => {
    return testKeys && !testModeWithoutSimulator
  },
  isGooglePayActive: (_state, { isActiveMethod }) =>
    isActiveMethod('GOOGLEPAY'),
  getGooglePayBrands: ({
    googlePay: { testMode },
    smartForm: { dnaCardBrands }
  }) => (testMode || !dnaCardBrands.length ? undefined : dnaCardBrands),
  isGooglePayCompatible: (_state, { isFirefox }) => !isFirefox
}
