<template lang="pug">  
  .kr-currency-conversion(v-if="hasCurrencyConversion")
    span.kr-currency-conversion__label(v-html="label")
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import formatter from '@/common/service/CurrencyFormatter'

export default {
  name: 'KryptonCurrencyConversion',
  computed: {
    ...mapGetters(['translate']),
    ...mapState(['language']),
    ...mapState({
      conversionCurrency: ({ dna }) => dna?.equivalentValueCurrency,
      conversionAmount: ({ dna }) => dna?.equivalentValue
    }),
    hasCurrencyConversion() {
      return !!(this.conversionCurrency && this.conversionAmount)
    },
    formattedAmount() {
      return formatter(
        this.conversionAmount,
        this.conversionCurrency,
        this.language
      )
    },
    label() {
      const baseLabel = this.translate('currency_conversion_label')
      return baseLabel
        .replace('[CURRENCY]', this.conversionCurrency)
        .replace('[AMOUNT]', this.formattedAmount)
    }
  }
}
</script>
