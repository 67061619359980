import { mapState } from 'vuex'
import PaymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'
import { parse } from 'bowser'

/**
 * Mixin to manage the grid configuration in the components that require it
 */
export default {
  computed: {
    ...mapState({
      configCols: ({ smartForm }) => {
        const columns = smartForm.otherPaymentMethods.grid.columns
        if (columns === 'max') return columns
        return parseInt(columns) || 1
      },
      configJustify: ({ smartForm }) =>
        smartForm.otherPaymentMethods.grid.justify,
      configColMinWidth: ({ smartForm }) => {
        const minWidth = smartForm.otherPaymentMethods.grid.minWidth
        return parseInt(minWidth) || 100
      }
    }),
    columns() {
      const groupConf = PaymentMethodsConf.groups[this.group]
      if (this.group && groupConf) {
        const groupCols = groupConf.grid?.columns || this.configCols
        // Group config auto falls back to global config
        return groupCols === 'auto' ? this.configCols : groupCols
      }
      return this.configCols
    },
    justify() {
      const groupConf = PaymentMethodsConf.groups[this.group]
      if (this.group && groupConf) {
        return groupConf.grid?.justify ?? this.configJustify
      }
      return this.configJustify
    },
    colMinWidth() {
      const groupConf = PaymentMethodsConf.groups[this.group]
      if (this.group && groupConf) {
        return groupConf.grid?.minWidth ?? this.configColMinWidth
      }
      return this.configColMinWidth
    }
  }
}
