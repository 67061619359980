import { mapObject } from 'underscore'
import { normalizeDictionary } from '@/common/service/BrandUtils'
import CardsRegexFallback from '@/configuration/sources/CardsRegexFallback.yml'

/** MIXINS */
import BrandMixin from '@/common/components/mixins/Brand'

export const PanLogicMixin = {
  mixins: [BrandMixin],
  created() {
    this.factoryRegex()
  },
  methods: {
    factoryRegex() {
      this.REGEX = mapObject(
        normalizeDictionary(CardsRegexFallback),
        x => new RegExp(x)
      )
    },

    detectBrandsFromNumber(number) {
      if (!this.paymentMethodToken) {
        const brands = this.cardTypeFromNumber(number)
        this.detectedBrands = brands
        this.unfilteredBrands = brands
      }
      return Promise.resolve()
    },

    /**
     * Establish the type of a card from the number.
     */
    cardTypeFromNumber(number) {
      const castedValue = `${number}`.replace(/\s/, '')
      for (const brand in this.REGEX) {
        if (this.REGEX[brand].test(castedValue)) {
          return this.isBrandAvailable(brand)
            ? this.adaptBrandWithNetworkSelect([brand])
            : ['DEFAULT']
        }
      }

      // If the detected brand is not available in the DNA, we remove it
      return ['DEFAULT']
    },

    adaptBrandWithNetworkSelect(brand) {
      if (this.networkSelectionMode) {
        const optionId = `${this.networkSelectionMode}`

        if (this.networkSelectionGrid?.hasOwnProperty(optionId)) {
          const optionConfiguration = this.networkSelectionGrid[optionId].values
          if (optionConfiguration.hasOwnProperty(brand)) {
            return optionConfiguration[brand]
          }
        }
      }
      return brand
    }
  }
}
