import { DomValidationError } from '@/common/errors/DomValidationError'

export default class DomValidator {
  allowedClassNames = ['kr-embedded', 'kr-smart-form', 'kr-smart-button']
  allowedClassesAsSelector

  embeddedFound = false
  smartFormFound = false
  errors = []
  $validElements = []

  constructor({ $store }) {
    this.$store = $store
    this.allowedClassesAsSelector = this.allowedClassNames
      .map(className => '.' + className)
      .join(', ')
  }

  reset() {
    this.$validElements = []
    this.errors = []
    this.smartFormFound = false
    this.embeddedFound = false
  }

  // $elements: HTMLElement[]
  validateElements($elements, allowEmpty = false) {
    this.reset()

    // Search for elements with the classes we need
    $elements = this.parseKRElements($elements)
    if ($elements.length === 0) {
      if (allowEmpty) return []
      else throw new DomValidationError('CLIENT_721')
    }

    // If any of the provided elements has more than 1 kr classes for the same element stop the render
    if ($elements.some($element => this.getKrFormClasses($element).length > 1))
      throw new DomValidationError('CLIENT_716')

    // Remove elements that are children of another element on the list (we only care for the upmost element if nested)
    $elements = $elements.filter(($elem, i) => {
      const $otherElements = [...$elements]
      $otherElements.splice(i, 1)
      return !$otherElements.some($e => $e.contains($elem))
    })

    // Validate each element individually according to its type
    $elements.forEach($element => {
      const krClass = this.getKrFormClasses($element)[0]
      switch (krClass) {
        case 'kr-smart-form':
          this.parseSmartForm($element)
          break
        case 'kr-smart-button':
          this.parseSmartButton($element)
          break
        case 'kr-embedded':
          this.parseEmbedded($element)
          break
      }
    })

    // Show errors (That don't stop the rendering process, but are still errors)
    this.dispatchErrors()

    return this.$validElements
  }

  parseSmartForm($element) {
    // if is smartform might have an internal embedded
    const $directChildEmbedded = $element.querySelector(':scope > .kr-embedded')

    // Check for elements that are not .kr-embedded inside the smartform
    if (
      $element.children.length > 1 ||
      ($element.children.length === 1 && !$directChildEmbedded)
    ) {
      // We cannot throw an error and stop the render here as originally planned. The WooCommerce plugin implementations does this
      // And we cannot break the plugin, so for the moment we can only show a warning error message
      const { translate, onErrorTranslationLoaded } = this.$store.getters
      onErrorTranslationLoaded(() => {
        console.warn('CLIENT_722: ' + translate('CLIENT_722'))
      })
      // throw new DomValidationError('CLIENT_722')
    }

    // check for multiple smartform elements found before
    if (this.smartFormFound) {
      this.errors.push('CLIENT_718')
    } else {
      // Valid .kr-smart-form
      this.$validElements.push($element)
      this.smartFormFound = true

      // Validate the internal embedded if present
      if ($directChildEmbedded) this.parseEmbedded($directChildEmbedded, true)
    }
  }

  parseSmartButton($element) {
    // Check for any child elements on the smartbutton element
    if ($element.children.length > 0) throw new DomValidationError('CLIENT_723')

    this.$validElements.push($element)
  }

  parseEmbedded($element, isInsideSmartform = false) {
    // .kr-embedded can contain any html inside except other kr elements
    if (this.getKRElements($element).length > 0)
      throw new DomValidationError('CLIENT_724')

    // check for multiple form elements found before
    if (this.embeddedFound) {
      this.errors.push('CLIENT_717')
    } else {
      // if inside smartform we don't add it to the valid elements list, as it is already part of it (inside the smartform element)
      if (!isInsideSmartform) this.$validElements.push($element)
      this.embeddedFound = true
    }
  }

  getKrFormClasses($element) {
    return $element.className
      .split(' ')
      .filter(cssClass => this.allowedClassNames.includes(cssClass))
  }

  parseKRElements($elements) {
    const $krElements = []

    $elements.forEach($element => {
      if (
        this.allowedClassNames.some(className =>
          $element.classList.contains(className)
        )
      ) {
        // the element itself is a kr element
        $krElements.push($element)
      } else {
        // element might be a wrapper containing kr elements
        const foundKRElements = this.getKRElements($element)
        $krElements.push(...foundKRElements)
      }
    })

    return $krElements
  }

  getKRElements($element) {
    return $element.querySelectorAll(this.allowedClassesAsSelector)
  }

  addKRElement($elements) {
    // sets the kr-element attribute, needed if used KR.renderElements with specific selector(s)
    $elements.forEach($element => $element.setAttribute('kr-element', ''))
  }

  dispatchErrors() {
    if (this.errors.length > 0) {
      this.$store.dispatch('error', {
        errorCode: 'CLIENT_715',
        children: this.errors.map(error => ({ errorCode: error })),
        metadata: {
          console: true
        }
      })
    }
  }
}
