<template lang="pug">
  .kr-sf-modal-wrapper(v-show="open")
    SmartFormGridModal(
      :open="open"
      v-on:close="closeModal"
      v-on:selected="openMethod"
    )
      template(v-slot:cardForm="")
        slot(name="cardForm")
      template(v-slot:extra="")
        slot(name="extra")
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import SmartFormGridModal from '@/host/components/smartform/grid/modal/Modal'

export default {
  name: 'SmartFormGridModalWrapper',
  components: {
    SmartFormGridModal
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['abortedErrorCode']),
    ...mapState(['receiptOpen']),
    ...mapState({
      activeMethod: state => state.smartForm.activeMethod
    })
  },
  methods: {
    ...mapActions(['error', 'closeSmartFormPopin']),
    closeModal() {
      if (this.receiptOpen) {
        this.$proxy.send(this.$storeFactory.create('closeReceipt', {}))
        return
      }
      if (this.activeMethod) this.error(this.abortedErrorCode)
      this.closeSmartFormPopin()
    },
    openMethod(method) {
      this.$emit('selected', method)
    }
  }
}
</script>
