var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", {
    directives: [
      {
        name: "onresize",
        rawName: "v-onresize",
        value: _vm.onResize,
        expression: "onResize",
      },
    ],
    domProps: { innerHTML: _vm._s(_vm.content) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }