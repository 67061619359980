import GridConfigMixin from '@/host/components/mixins/grid/Config'

/**
 * Mixin to manage the button label visibility
 */
export default {
  mixins: [GridConfigMixin],
  props: {
    showLabel: {
      type: Boolean,
      default: true
    },
    marginBtns: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      forcedCompute: 0 // Helper to force recompute
    }
  },
  computed: {
    labelClasses() {
      return {
        'kr-method-btn__label--hidden': !this.showLabel
      }
    },
    buttonLabelClasses() {
      return {
        'kr-method-btn--no-label': !this.showLabel
      }
    }
  },
  watch: {
    columns: 'onGridChange',
    justify: 'onGridChange'
  },
  methods: {
    /**
     * Listen to grid and size changes to recompute the label visibility
     */
    async onGridChange() {
      this.forcedCompute++
      await this.$nextTick() // Wait for the DOM to update
      const size = { width: this.$el.clientWidth }
      if (size.width === 0) return

      this.computeLabelVisibility(size)
    },

    // Compute the visibility of the label
    async computeLabelVisibility(size) {
      await this.$nextTick() // Wait for the DOM to update
      // Collect content sizes
      const $radioButton = this.$refs.radioButton
      const radioButtonWidth =
        ($radioButton?.clientWidth || $radioButton?.$el?.clientWidth) ?? 0
      const iconWidth = this.$refs.icon?.clientWidth ?? 0
      const labelWidth = this.$refs.label?.clientWidth ?? 0
      let requiredWidth = radioButtonWidth + iconWidth + labelWidth
      const { width, gap } = this.getGridElementSizes()

      // Regular calculation - required width is smaller than the available space
      let hideLabel = requiredWidth >= size.width
      // Margin calculation - take into account the split buttons
      const margin = size.width - requiredWidth
      if (this.marginBtns && margin > 0 && !this.showLabel) {
        const requiredMargin = Math.ceil(
          this.marginBtns *
            ((requiredWidth + parseInt(gap)) / this.computeColumns(width, gap))
        )
        hideLabel = requiredMargin >= margin
      }

      // Hide the label if there is not enough space
      if (hideLabel) this.$emit('hide-label')
      else this.$emit('show-label')
    }
  }
}
