import { mapState, mapGetters } from 'vuex'
import { dynamicMapState, dynamicMapGetters } from '@/common/util/store'

export const VisibilityMixin = {
  computed: {
    ...mapState(['formMode', 'formToken', 'dna', 'redirectIframeOpen']),
    ...mapState({
      dnaCards: state => state.dna?.tokens?.cards || [],
      defaultConfiguration(state) {
        return state.dna?.cards?.DEFAULT?.fields[this.fieldName] || {}
      }
    }),
    ...mapGetters(['isBrandAvailable', 'isAnyPopinOpen', 'isWalletSmartForm']),
    ...dynamicMapGetters('context.namespace', ['isWalletMyCards']),
    ...dynamicMapState('context.namespace', [
      'bin8',
      'selectedBrand',
      'walletMode'
    ]),
    ...dynamicMapState('context.namespace', {
      payToken: state => state.nonSensitiveValues.paymentMethodToken
    })
  },
  watch: {
    selectedBrand: 'resolveVisibility',
    dna: {
      deep: true,
      handler: 'resolveVisibility'
    },
    formMode: 'resolveVisibility',
    walletMode: 'resolveVisibility',
    redirectIframeOpen: 'resolveVisibility',
    availableOptions: 'resolveVisibility'
  },
  created() {
    this.resolveVisibility()
  },
  methods: {
    shouldBeDisplayed() {
      if (this.fieldType === 'select') {
        return !!(this.availableOptions.length || this.isReadOnly)
      }

      return true
    },
    isReadOnlyInstallments(brand) {
      if (this.fieldType === 'select') {
        return !!(this.hasInstallments && this.isReadOnly)
      }

      return false
    },
    resolveVisibility() {
      if (this.isWalletMyCards) {
        this.myCardsVisibility()
      } else if (this.formMode === 'token') {
        this.paymentTokenVisibility()
      } else {
        this.isDisabled = false
        this.defaultVisibility()
      }
    },

    /**
     * Default check, dna main conf
     */
    defaultVisibility() {
      let isVisible = false
      if (this.defaultConfiguration) {
        const conf = this.configuration || this.defaultConfiguration
        isVisible =
          this.shouldBeDisplayed() &&
          (this.isReadOnlyInstallments(this.selectedBrand) ||
            conf.hidden === false)
      }
      this.isVisible = isVisible
    },

    /**
     * My Cards Visibility (wallet) - Check the token conf
     */
    myCardsVisibility() {
      // Check the visibility in the tokens
      for (const card of this.dnaCards) {
        if (this.payToken && card.token !== this.payToken) continue
        const field = card.fields[this.fieldName]
        if (field && field.hidden === false) {
          this.isVisible = true
          return
          // Expiry is always visible and disabled
          // If there is only one card and field is pan, display pan
          // However in SmartForm the pan shall always been displayed
        } else if (
          (this.fieldName === 'expiryDate' && field) ||
          (this.fieldName === 'pan' &&
            ((this.isWalletMyCards && this.dnaCards.length === 1) ||
              this.isWalletSmartForm))
        ) {
          this.isVisible = true
          this.isDisabled = true
          return
          // If the field has no conf and it's installments field, fallback
          // to the default visibility check
        } else if (this.fieldName === 'installmentNumber') {
          this.defaultVisibility()
          return
        }
      }

      this.isVisible = this.fieldName === 'walletCardList'
    },

    /**
     * Payment token Visibility (forced token)
     */
    paymentTokenVisibility() {
      if (this.dnaCards) {
        const card = this.dnaCards[0]
        const field = card?.fields[this.fieldName]
        if (field && field.hidden === false) {
          this.isVisible = true
          return
        } else if (field && field.value) {
          this.isDisabled = true
          this.isVisible = true
          return
        }
        // If the field has no conf, fallback to the default visibility check
        this.defaultVisibility()
        return
      }
      this.isVisible = false
    }
  }
}
