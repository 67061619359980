import _ from 'underscore'

import Blender from '@/host/Blender'
import Communicator from '@/common/comm/Communicator'
import ConfigurationHandler from '@/host/service/ConfigurationHandler.js'
import DNA from '@/host/service/DNA'
import StyleChecker from '@/host/service/StyleChecker'
import EndpointDetector from '@/common/service/EndpointDetector'
import FormEvents from '@/host/service/FormEvents'
import FormRenderer from '@/host/FormRenderer'
import FormStyler from '@/host/FormStyler'
import GhostBlender from '@/ghost/Blender'
import GhostQueue from '@/host/service/queues/GhostQueue'
import AllReadyQueue from '@/host/service/queues/AllReadyQueue'
import HostApi from '@/host/Api'
import IFrameBuilder from '@/host/IFrameBuilder'
import IFrameController from '@/host/service/IFrameController'
import LazyLoader from '@/host/service/LazyLoader'
import DomReader from '@/host/DomReader'
import PluginManager from '@/host/service/PluginManager'
import ProxyBridge from '@/common/service/ProxyBridge'
import StaticFinder from '@/host/service/StaticFinder'
import ThemeHandler from '@/host/service/ThemeHandler'
import StrategyDetector from '@/ghost/service/StrategyDetector'
import IFrameHandler from '@/ghost/service/IFrameHandler'
import StoreFactory from '@/common/service/StoreFactory'
import TimerManager from '@/host/service/TimerManager'
import TokenReader from '@/host/service/TokenReader'
import Url from '@/common/service/Url'
import EventNotifier from '@/host/service/EventNotifier'
import RiskManager from '@/host/service/RiskManager'
import Logger from '@/common/service/Logger'
import Checker from '@/host/service/Checker'
import Environment from '@/host/service/Environment'
import BusListener from '@/host/service/BusListener'
import storeIndex from '@/store/index'
import { loadRedirectServices } from '@/common/loader/redirect'
import FormCleaner from '@/host/FormCleaner'
import DomValidator from '@/host/render/DomValidator'
import CallbackHandler from '@/host/service/CallbackHandler'
import ApplePayManager from '@/host/service/ApplePayManager'
import GooglePayManager from '@/ghost/service/GooglePayManager'

// Payment
import BrowserRequests from '@/ghost/service/Requests/BrowserRequests'
import RestAPI from '@/ghost/service/Requests/RestAPI'
import ResponseHandler from '@/ghost/service/ResponseHandler'
import FocusHandler from '@/ghost/service/FocusHandler'
import ValidationManager from '@/ghost/service/ValidationManager'
import PaymentTrigger from '@/ghost/service/Triggers/PaymentTrigger'
import DeleteTokenTrigger from '@/ghost/service/Triggers/DeleteTokenTrigger'
import ApplePayTokenTrigger from '@/ghost/service/Triggers/ApplePayTokenTrigger'
import GetPayloadInfosTrigger from '@/ghost/service/Triggers/GetPayloadInfosTrigger'
import AppleOrGooglePayPaymentTrigger from '@/ghost/service/Triggers/AppleOrGooglePayPaymentTrigger'
import RedirectTrigger from '@/ghost/service/Triggers/RedirectTrigger'
import TransactionTrigger from '@/ghost/service/Triggers/TransactionTrigger'
import TokenPaymentTrigger from '@/ghost/service/Triggers/TokenPaymentTrigger'
import DeclinePartialPaymentTrigger from '@/ghost/service/Triggers/DeclinePartialPaymentTrigger'
import LyraAuth from '@lyraAuth/pass-client-lib/src/index'

export default async (application, enabledCommunicators = true, $bus) => {
  const locator = {
    application,
    $bus,
    storeFactory: new StoreFactory()
  }

  const isNotSlave = _.contains(['host', 'ghost', 'redirect'], application)

  // Proxy + communicator
  if (isNotSlave) {
    locator.url = new Url()
    if (enabledCommunicators)
      locator.communicator = new Communicator(application, locator)
    locator.proxy = new ProxyBridge(locator)
  }

  // Services
  locator.logger = new Logger(locator)

  // Start store
  locator.$store = await storeIndex(locator, application)

  // Non-slave services
  if (isNotSlave) {
    locator.endpointDetector = new EndpointDetector(locator)
    locator.browserRequests = new BrowserRequests(locator)
  }

  await Environment.init(locator)

  // Specific services per application
  switch (application) {
    case 'host':
      locator.timerManager = new TimerManager(locator)
      locator.iframeBuilder = new IFrameBuilder(locator)
      locator.checker = new Checker(locator)
      locator.staticFinder = new StaticFinder(locator)
      locator.themeHandler = new ThemeHandler(locator)
      locator.styleChecker = new StyleChecker(locator)
      locator.lazyLoader = new LazyLoader()
      locator.busListener = new BusListener(locator)
      locator.ghostQueue = new GhostQueue(locator)
      locator.allReadyQueue = new AllReadyQueue(locator)
      locator.tokenReader = new TokenReader(locator)
      locator.dna = new DNA(locator)
      locator.configurationHandler = new ConfigurationHandler(locator)
      locator.api = new HostApi(locator)
      locator.formEvents = new FormEvents(locator)
      locator.domReader = new DomReader(locator)
      locator.domValidator = new DomValidator(locator)
      locator.formStyler = new FormStyler()
      locator.formRenderer = new FormRenderer(locator)
      locator.formCleaner = new FormCleaner(locator)
      locator.riskManager = new RiskManager(locator)
      locator.iframeController = new IFrameController(locator)
      locator.pluginManager = new PluginManager(locator)
      locator.eventNotifier = new EventNotifier(locator)
      locator.blender = new Blender(locator)
      locator.callbackHandler = new CallbackHandler(locator)
      locator.applePayManager = new ApplePayManager(locator)
      break
    case 'ghost':
      locator.blender = new GhostBlender(locator)
      locator.iframeHandler = new IFrameHandler(locator)
      locator.strategyDetector = new StrategyDetector(locator)
      // Payment
      locator.restAPI = new RestAPI(locator)
      locator.validationManager = new ValidationManager(locator)
      locator.responseHandler = new ResponseHandler(locator)
      locator.focusHandler = new FocusHandler(locator)
      locator.lyraAuth = new LyraAuth({
        debug: process.env.NODE_ENV === 'development'
      })
      locator.googlePayManager = new GooglePayManager(locator)

      // Register triggers
      new PaymentTrigger(locator)
      new DeleteTokenTrigger(locator)
      new RedirectTrigger(locator)
      new TransactionTrigger(locator)
      new ApplePayTokenTrigger(locator)
      new AppleOrGooglePayPaymentTrigger(locator)
      new GetPayloadInfosTrigger(locator)
      new TokenPaymentTrigger(locator)
      new DeclinePartialPaymentTrigger(locator)
      break
    case 'redirect':
      await loadRedirectServices(locator)
      break
  }

  return locator
}
