import { intersection } from 'underscore'
import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'
import Events from '@/configuration/Events'
import {
  initSmartformMethods,
  initPaymentMethodGroups
} from '@/store/utils/smartform'
import Modal from '@/configuration/sources/SimpleModal.yml'
import { MethodDescriptor } from '@/common/model/PaymentMethod'
import { isSmartFormForcedTokenHelper } from '@/host/service/DNA/Helper/isSmartFormForcedTokenHelper.js'
import { CoBrands, SubBrands } from '@/configuration/sources/Brands.yml'
import { areEqualSets } from '@/common/util/array'
import { getLayoutState } from '@/store/modules/layout'

export const getSmartFormState = () => {
  return {
    smartForm: {
      ...getLayoutState(),
      type: 'embedded', // popin
      size: 'M', // XS, S, M, L, XL
      displayOptions: {
        cardsIntegrated: true, // only used to read the html attribute (use the cardsFormExpanded getter instead)
        cardHeader: true
      },
      labels: {
        otherPaymentMethods: null
      },
      defaultMethod: '',
      selectedMethod: null,
      activeMethod: null,
      activeMethodMetadata: null,
      saveMethod: {}, // [METHOD]: boolean
      deadEndPaymentMethod: false,
      forceRedirectionType: null,
      methodDisplayMode: 'MINIMAL',
      outsideCardsForm: false,
      hidden: false,
      isOpen: false,
      paymentDone: false,
      // Methods
      paymentMethods: initSmartformMethods(),
      availablePaymentMethods: [],
      dnaPaymentMethods: [],
      userPaymentMethodsOrder: [],
      allowedPaymentMethods: ['all'],
      // Brands
      dnaCardBrands: [],
      cardBrands: [],
      // Smart Buttons
      smartButtons: [],
      // Navigation
      navigation: {
        views: ['full', 'group', 'content'], // full, group, content
        currentView: '',
        history: []
      },
      // Grouping
      groupingThreshold: 7,
      activeGroup: null,
      paymentMethodGroups: initPaymentMethodGroups(),
      singlePaymentButton: false
    }
  }
}

export const smartFormMutations = {}

export const smartFormActions = ($locator, app) => {
  return {
    /**
     * Single Payment Button Mode
     * Open previously selected payment method.
     * Raise error if widget is not smartform.
     * Raise warning if no payment method has been selected.
     *
     * @since KJS-2614
     */
    openSelectedMethod({ state, dispatch, getters }) {
      const { isSmartForm, isFallbackSmartButtonVisible } = getters
      let { selectedMethod } = state.smartForm

      dispatch('cleanError')

      if (!isSmartForm) return dispatch('error', 'CLIENT_509')

      // If using the smartButton fallback with only 1 payment method select it by default
      if (isFallbackSmartButtonVisible) {
        const { availablePaymentMethods } = state.smartForm
        dispatch('selectMethod', availablePaymentMethods[0])
      }

      selectedMethod = state.smartForm.selectedMethod
      if (!selectedMethod) {
        $locator.$bus.$emit(Events.krypton.smartform.warningFlash)
        return dispatch('warning', {
          errorCode: 'CLIENT_601',
          metadata: {
            showInForm: true
          }
        })
      }
      // Check first if form card is expanded and user started to fill it
      if (selectedMethod === 'CARDS' && getters.cardsFormExpanded) {
        $locator.$bus.$emit(Events.krypton.form.pay, state.forms.main)
        return
      }
      dispatch('openMethod', selectedMethod)
    },
    /**
     * Single Payment Button Mode
     * Unselect previously selected method.
     *
     * @since KJS-2614
     */
    unselectMethod({ state, commit }) {
      const { smartForm } = state
      if (smartForm.selectedMethod) {
        commit('UPDATE', {
          smartForm: {
            selectedMethod: null
          }
        })
      }
    },
    /**
     * Single Payment Button Mode
     * Select method without opening it yet.
     *
     * @since KJS-2614
     */
    selectMethod({ state, commit, dispatch }, selectedMethod) {
      dispatch('cleanError')
      if (state.smartForm.selectedMethod !== selectedMethod) {
        $locator.$bus.$emit(
          Events.krypton.smartform.paymentMethodSelected,
          selectedMethod
        )
        commit('UPDATE', {
          smartForm: {
            selectedMethod
          }
        })
      }
    },
    closeMethod({ state, commit, dispatch, getters }, backNavigation = false) {
      const method = state.smartForm.activeMethod
      const { formMode } = state

      // Skip the action if the method is already closed
      if (!method) return
      if (method === 'CARDS' && formMode === 'wallet') {
        const formId = getters.cardsFormExpanded
          ? state.forms.clone
          : state.forms.main
        dispatch(`cardForm_${formId}/closeWalletPayment`)
      } else if (method === 'APPLE_PAY') dispatch('closeApplePay')

      // Reset the method
      dispatch('finishRedirection')
      commit('UPDATE', {
        smartForm: {
          activeMethod: null,
          activeMethodMetadata: null
        }
      })
      // For window redirection, we check the transaction separately
      if (method && getters.getRedirectionType(method) !== 'iframe')
        dispatch('checkTransaction')

      const {
        isExtrasFormVisible,
        isSmartFormPopin,
        isGroupActive,
        isPaymentDone
      } = getters

      dispatch('setNavigableElements')
      // Extras form - Manage separately the navigation
      if (isExtrasFormVisible && !backNavigation) return

      const { isNavigationOnContent } = getters
      if (isNavigationOnContent || backNavigation) dispatch('navigateBack')

      if ((!isSmartFormPopin && !isGroupActive) || isPaymentDone) {
        dispatch('update', { smartForm: { isOpen: false } })
      }
      if (state.activeForm !== 'main') {
        dispatch('focusForm', 'main')
      }
    },
    openMethod({ dispatch, commit, getters, state }, method) {
      if (!method) return
      method = MethodDescriptor.create(method)

      const activeMethod = method.name
      const metadata = method.metadata

      dispatch('cleanError')

      // Wallet Payment
      if (getters.hasToken(metadata) && getters.isCardMethod(activeMethod)) {
        dispatch(`startWalletPayment`, {
          method: activeMethod,
          paymentMethodToken: metadata
        })
        // Continue the flow only for cards
        if (!getters.hasCardToken(metadata)) return
      }

      // Cards Form Popin fallback to open the popin
      if (getters.isCardsFormPopin && getters.isCardMethod(activeMethod)) {
        dispatch('openPopin')
        return
      }
      const redirectionType = getters.getRedirectionType(activeMethod)
      const isMethodAvailableInDna =
        getters.isMethodAvailableInDna(activeMethod)

      if (!isMethodAvailableInDna) {
        // Active Payment method not available
        dispatch('error', 'CLIENT_503')
      } else if (activeMethod === 'GOOGLEPAY') {
        $locator.proxy.send(
          $locator.storeFactory.create('startGooglePay'),
          false,
          'payment'
        )
      } else if (activeMethod === 'APPLE_PAY') {
        // Apple Pay
        dispatch('startApplePayPayment')
      } else if (
        getters.isCardMethod(activeMethod) &&
        (getters.hasCardToken(metadata) ||
          !getters.cardsFormExpanded ||
          getters.hasSmartButton(activeMethod))
      ) {
        // Card method
        commit('UPDATE', {
          smartForm: {
            activeMethod,
            activeMethodMetadata: metadata ?? null,
            deadEndPaymentMethod: false
          }
        })
        const view =
          !!metadata && (getters.cardsFormExpanded || getters.isFormPopin)
            ? 'extra'
            : 'content'
        const withoutHistory = getters.hasSmartButton(activeMethod)
        if (!getters.isAnyPopinOpen)
          dispatch('openSmartFormPopin', { withHistory: !withoutHistory })
        dispatch('setNavigableElements')
        dispatch('navigate', view)
        if (view === 'extra') {
          dispatch('focusForm', 'clone')
        }
      } else if (getters.isDemoToken) {
        dispatch('error', {
          errorCode: 'CLIENT_998',
          paymentMethod: activeMethod
        })
      } else if (app === 'ghost' || window.redirectionWindow) {
        commit('UPDATE', {
          smartForm: {
            activeMethod,
            activeMethodMetadata: metadata ?? null,
            deadEndPaymentMethod:
              paymentMethodsConf.deadEndPaymentMethod.includes(activeMethod) ||
              !!state.dna.smartForm?.[activeMethod]?.deadEndPaymentMethod,
            methodDisplayMode:
              paymentMethodsConf.displayMode[activeMethod] ||
              getSmartFormState().smartForm.methodDisplayMode
          },
          redirectionType
        })
        if (redirectionType === 'iframe') {
          if (!state.smartForm.isOpen) dispatch('openSmartFormPopin')
          dispatch('setNavigableElements')
          dispatch('navigate', 'content')
        }
      } else if (redirectionType === 'iframe') {
        commit('UPDATE', {
          smartForm: {
            activeMethod
          }
        })
        $locator.proxy.send(
          $locator.storeFactory.create('redirect', {
            paymentMethod: activeMethod
          })
        )
      } else if (~['popup', 'tab'].indexOf(redirectionType)) {
        if (
          activeMethod.startsWith('PAYPAL') &&
          !!state.amount &&
          (state.dna.formAction === 'CUSTOMER_WALLET' ||
            state.dna.formAction === 'ASK_REGISTER_PAY') &&
          state._internals.doRegister.forced !== true &&
          !getters.hasToken(metadata)
        ) {
          const layouts = Modal.layouts
          dispatch('openModal', {
            layout: layouts.PAYPAL.registerMethod,
            method: activeMethod
          })
        } else {
          dispatch('showWindow', activeMethod)
        }
      }
    },
    setSaveMethod({ commit }, { method }) {
      commit('UPDATE', { smartForm: { saveMethod: { [method]: true } } })
    },
    unsetSaveMethod({ commit }, { method }) {
      commit('UPDATE', { smartForm: { saveMethod: { [method]: false } } })
    },
    openGroup({ commit, dispatch }, activeGroup) {
      commit('UPDATE', { smartForm: { activeGroup } })
      dispatch('cleanError')
      dispatch('openSmartFormPopin')
    },
    closeGroup({ dispatch, commit }) {
      dispatch('closeMethod')
      commit('UPDATE', { smartForm: { activeGroup: null } })
    },
    setPaymentMethods({ commit, dispatch, state, getters }, paymentMethods) {
      const { hasSmartButton } = getters

      const defaultMethod = paymentMethods[0] ? paymentMethods[0] : 'CARDS'

      commit('UPDATE', {
        smartForm: {
          availablePaymentMethods: paymentMethods.filter(
            method => !hasSmartButton(method)
          ),
          dnaPaymentMethods: paymentMethods,
          defaultMethod
        }
      })
      if (~paymentMethods.indexOf('APPLE_PAY')) dispatch('applePayMode')
      if (~paymentMethods.indexOf('GOOGLEPAY')) dispatch('setupGooglePayHost')

      dispatch(
        'setPaymentMethodGroups',
        state.smartForm.availablePaymentMethods
      )
    },
    setAvailablePaymentMethods({ commit, dispatch }, paymentMethods) {
      commit('UPDATE', {
        smartForm: {
          availablePaymentMethods: paymentMethods
        }
      })
      dispatch('setPaymentMethodGroups', paymentMethods)
    },
    removePaymentMethod({ commit, state }, paymentMethod) {
      commit('UPDATE', {
        smartForm: {
          availablePaymentMethods:
            state.smartForm.availablePaymentMethods.filter(
              method => method !== paymentMethod
            )
        }
      })

      if (paymentMethod === 'CARDS') return
    },
    removeDnaPaymentMethod({ commit, state }, paymentMethod) {
      commit('UPDATE', {
        smartForm: {
          dnaPaymentMethods: state.smartForm.dnaPaymentMethods.filter(
            method => method !== paymentMethod
          )
        }
      })
    },
    setPaymentMethodGroups({ state, commit }, paymentMethods) {
      const groups = { single: [] }

      // Grouped methods
      for (const name in paymentMethodsConf.groups) {
        const groupMethods = intersection(
          paymentMethods,
          paymentMethodsConf.groups[name].methods
        )
        if (groupMethods.length > 1) {
          paymentMethods = paymentMethods.filter(
            method => !groupMethods.includes(method)
          )
          groups[name] = groupMethods
        }
      }

      // Ungrouped methods
      for (const method of paymentMethods) {
        // Cards method
        if (
          !state.smartForm.displayOptions.cardsIntegrated &&
          method === 'CARDS'
        )
          groups.single.unshift('CARDS')
        else {
          groups.single.push(method)
        }
      }

      // Order the groups
      const orderedGroups = Object.keys(groups)
        .sort((a, b) => {
          const groupOrder = Object.keys(paymentMethodsConf.groups)
          return groupOrder.indexOf(a) - groupOrder.indexOf(b)
        })
        .reduce((obj, key) => {
          obj[key] = groups[key]
          return obj
        }, {})

      // Update the state
      commit('UPDATE', { smartForm: { paymentMethodGroups: orderedGroups } })
    },
    paymentProcessed({ commit, dispatch }) {
      commit('UPDATE', {
        smartForm: {
          paymentDone: true
        }
      })
      dispatch('disableForm')
    },
    closeSmartFormPopin({ state, commit, dispatch, getters }) {
      // Skip it in case the popin is not open (can be embedded and popin mode)
      if (!state.smartForm.isOpen) return
      if (!getters.isDeadEndPaymentMethod && !getters.isAbortedError)
        dispatch('cleanError')

      // Except when the 3ds is open, we clear all the fields
      const formId = state.forms[state.activeForm]
      if (!state.processingPayment)
        dispatch(`cardForm_${formId}/forceFieldClear`)

      // Close modal
      commit('UPDATE', { smartForm: { isOpen: false } })
      if (app === 'host') {
        const $ghost = $locator.iframeController.ghostContainer
        $ghost.hide()
      }
      dispatch('closeGroup')
      dispatch('runCallback', 'onPopinClosed')
    },
    openSmartFormPopin({ commit, state, dispatch, getters }, options = {}) {
      const { withHistory = true } = options
      if (withHistory)
        dispatch('navigate', state.smartForm.activeGroup ? 'group' : 'full')
      commit('UPDATE', { smartForm: { isOpen: true } })
    },
    openSmartFormPopinWrapper({ state, dispatch, getters }) {
      if (getters.popinOpensMethodDirectly)
        dispatch('openMethod', state.smartForm?.availablePaymentMethods[0])
      else dispatch('openSmartFormPopin')
    },
    setCardBrands({ commit }, brands) {
      brands.splice(brands.indexOf('DEFAULT'), 1)
      commit('UPDATE', {
        smartForm: { dnaCardBrands: brands, cardBrands: brands }
      })
    },
    removeCardBrands({ commit, dispatch, state }, brands) {
      const cardBrands = state.smartForm.cardBrands
      if (typeof brands === 'string') {
        brands = brands.toUpperCase().split(',')
      }
      // Filter the brands (including the sub-brands and co-brands)
      const filteredBrands = cardBrands.filter(brand => {
        return (
          !brands.includes(brand) &&
          !brands.includes(CoBrands.brands[brand]) &&
          !brands.includes(CoBrands.onlyOneBrand[brand]) &&
          !brands.includes(SubBrands[brand])
        )
      })

      // Update card brands if there has been any filtering
      if (!areEqualSets(filteredBrands, cardBrands))
        commit('UPDATE', { smartForm: { cardBrands: filteredBrands } })
      if (filteredBrands.length === 0) {
        dispatch('removePaymentMethod', 'CARDS')
      }
    },
    navigate({ commit, state }, view) {
      const { history, currentView } = state.smartForm.navigation
      if (view !== currentView) {
        history.push(view)
        commit('UPDATE', {
          smartForm: {
            navigation: { currentView: view, history }
          }
        })
      }
    },
    navigateBack({ commit, state, dispatch, getters }) {
      const { isGridMode } = getters
      const { history: historyParam, currentView } = state.smartForm.navigation
      const history = [...historyParam]
      history.pop() // remove the last view

      if (history.length) {
        if (currentView === 'group' && !isGridMode) dispatch('closeGroup')
        commit('UPDATE', {
          smartForm: {
            navigation: {
              currentView: history[history.length - 1],
              history
            }
          }
        })
      } else {
        commit('UPDATE', {
          smartForm: {
            navigation: { history }
          }
        })
        // Do not close the popin in case of error (Apple Pay simulator)
        if (!getters.hasError) dispatch('closeSmartFormPopin')
      }
      // Finish the redirection - in case of
      dispatch('finishRedirection')
    },
    addSmartButton({ commit, state, dispatch }, paymentMethod) {
      commit('UPDATE', {
        smartForm: {
          smartButtons: [paymentMethod, ...state.smartForm.smartButtons]
        }
      })
      if (paymentMethod !== 'CARDS') {
        dispatch('removePaymentMethod', paymentMethod)
      }
    },
    displaySmartFormModal({ state, dispatch, getters }) {
      // In some cases SmartFormModal must be opened to display
      // SmartForm + expanded card form + 3DS:
      // Dcc planet iframe and receipt
      if (state.formWidget === 'smartForm' && !getters.isSmartFormOpen) {
        dispatch('openSmartFormPopin')
        dispatch('setNavigableElements')
        dispatch('navigate', 'content')
      }
    }
  }
}
export const smartFormGetters = {
  isSmartFormSmall: ({ smartForm }) => smartForm.size.substr(-1) === 'S',
  isSmartFormMedium: ({ smartForm }) => smartForm.size === 'M',
  isSmartFormLarge: ({ smartForm }) => smartForm.size.substr(-1) === 'L',
  isSmartForm: ({ formWidget }) => formWidget === 'smartForm',
  isSmartFormPopin: ({ formWidget, smartForm }) =>
    formWidget === 'smartForm' && smartForm.type === 'popin',
  isSmartFormOpen: ({ formWidget, smartForm }) =>
    formWidget === 'smartForm' && smartForm.isOpen,
  isSmartFormCompact: ({ smartForm }, { isSmartFormPopin }) =>
    smartForm.layout === 'compact' && !isSmartFormPopin,
  isSinglePaymentButton: ({ smartForm }, { isSmartFormPopin }) =>
    smartForm.singlePaymentButton && !isSmartFormPopin,
  isCardMethod:
    ({ smartForm: { activeMethod } }) =>
    (method = activeMethod) =>
      method === 'CARDS',
  isSelectedMethod:
    ({ smartForm: { selectedMethod } }) =>
    method =>
      method === selectedMethod,
  isActiveMethod:
    ({ smartForm: { activeMethod } }) =>
    method =>
      method === activeMethod,
  isActiveGroup:
    ({ smartForm: { activeGroup } }) =>
    method =>
      method === activeGroup,
  isGroupActive: ({ smartForm: { activeGroup } }) => activeGroup !== null,
  hasCardBrand: ({ smartForm: { dnaCardBrands } }) => {
    return brand => !!~dnaCardBrands.indexOf(brand)
  },
  hasAnyCardBrand: ({ smartForm: { cardBrands } }) => cardBrands.length > 0,
  hasCardMethodOption: ({ smartForm: { availablePaymentMethods } }) =>
    !!~availablePaymentMethods?.indexOf('CARDS'),
  hasCardMethodAvailable: ({ smartForm: { dnaPaymentMethods }, dna }) =>
    !!~dnaPaymentMethods?.indexOf('CARDS'),
  hasCardInRoot: (state, getters) =>
    getters.hasCardMethodAvailable && !getters.hasSmartButton('CARDS'),
  hasAnyActiveMethod: ({ smartForm }) => !!smartForm.activeMethod,
  isCardMethodActive: ({ smartForm }) => smartForm.activeMethod === 'CARDS',
  isMethodAvailable: state => method => {
    return !!~state.smartForm.availablePaymentMethods.indexOf(method)
  },
  isMethodAvailableInDna: state => method => {
    return !!~state.smartForm.dnaPaymentMethods.indexOf(method)
  },
  isMethodWhitelisted:
    ({ _internals }) =>
    method => {
      return !_internals.devMode
        ? paymentMethodsConf.whitelist.includes(method)
        : true
    },
  hasSeveralPaymentMethods: (_state, { numOfPaymentMethods, isWallet }) =>
    numOfPaymentMethods > 1 || (numOfPaymentMethods === 1 && isWallet),
  hasPaymentMethods: (state, { numOfPaymentMethods }) =>
    numOfPaymentMethods > 0,
  numOfPaymentMethods: ({ smartForm }) =>
    smartForm?.availablePaymentMethods?.length,
  getRedirectionType:
    ({ dna, smartForm }, getters) =>
    method => {
      // For cards - always embedded
      if (getters.isCardMethod(method)) return 'embedded'
      // First priority - forced type
      if (smartForm.forceRedirectionType) return smartForm.forceRedirectionType
      const configuredType = paymentMethodsConf.type[method]
      // Second priority - configuration
      if (configuredType) return configuredType
      // Third priority - dna
      if (dna?.smartForm?.[method]?.allowIFrame) return 'iframe'
      // Default - popup
      return 'popup'
    },
  isDeadEndPaymentMethod: ({ smartForm }) => smartForm.deadEndPaymentMethod,
  hasDeadEndPaymentMethod:
    ({ dna, smartForm: { activeMethod } }) =>
    (method = activeMethod) =>
      paymentMethodsConf.deadEndPaymentMethod?.includes(method) ||
      dna.smartForm?.[method]?.deadEndPaymentMethod,
  paymentMethod: ({ smartForm }) => {
    return smartForm?.activeMethod // KJS-3606
  },
  paymentMethodMetadata: ({ smartForm }) => smartForm.activeMethodMetadata,
  isGroupingActivated: (
    { smartForm },
    { numOfPaymentMethods, isSinglePaymentButton }
  ) =>
    numOfPaymentMethods > smartForm.groupingThreshold && !isSinglePaymentButton,
  hasSmartButton:
    ({ smartForm }) =>
    method => {
      return !!smartForm.smartButtons.find(buttonMethod =>
        buttonMethod.includes(method)
      )
    },
  hasExactSmartButton:
    ({ smartForm }) =>
    method => {
      return smartForm.smartButtons?.includes(method)
    },
  isExactSmartButtonActive: ({ smartForm }, { hasExactSmartButton }) =>
    smartForm.activeMethod &&
    hasExactSmartButton(
      MethodDescriptor.fromArray([
        smartForm.activeMethod,
        smartForm.activeMethodMetadata
      ]).toString()
    ),
  isSmartButtonActive: ({ smartForm }, { hasSmartButton }) =>
    hasSmartButton(smartForm.activeMethod),
  // checks if card form must be displayed as a separated extended form
  cardsFormExpanded: (
    { smartForm },
    {
      hasCardMethodOption,
      hasSeveralPaymentMethods,
      isWallet,
      allCardBrandsFiltered
    }
  ) => {
    return (
      (!smartForm.displayOptions.cardsIntegrated ||
        (hasCardMethodOption && !hasSeveralPaymentMethods && !isWallet)) &&
      smartForm.type === 'embedded' &&
      !allCardBrandsFiltered
    )
  },
  outsideCardsForm: ({ smartForm }) => smartForm.outsideCardsForm,
  hasCardHeader: (
    { smartForm },
    { cardsFormExpanded, hasAnyCardBrand, isWallet, hasTokens }
  ) =>
    !!cardsFormExpanded &&
    !!smartForm.displayOptions.cardHeader &&
    hasAnyCardBrand &&
    !smartForm.outsideCardsForm &&
    (!isWallet || !hasTokens),

  groupedPaymentMethods: ({ smartForm }) => {
    const grouped = []
    for (const group in smartForm.paymentMethodGroups) {
      if (group !== 'single')
        grouped.push(...smartForm.paymentMethodGroups[group])
    }
    return grouped
  },
  activeGroupPaymentMethods: ({ smartForm }) => {
    return smartForm.paymentMethodGroups[smartForm.activeGroup] || []
  },
  getPaymentMethodLabel: (_, getters) => paymentMethod => {
    const key = `smartform_method_${paymentMethod.toLowerCase()}`
    const translation = getters.translate(key)
    if (translation !== key) return translation
    return paymentMethodsConf.labels[paymentMethod] || paymentMethod
  },
  getCustomPaymentMethodLabel:
    ({ smartForm, country }) =>
    paymentMethod => {
      return smartForm.paymentMethods?.[paymentMethod]?.label || null
    },
  getCustomPaymentMethodIcon:
    ({ smartForm }) =>
    paymentMethod => {
      if (!smartForm.paymentMethods?.[paymentMethod]?.icon) return null
      const icon = smartForm.paymentMethods[paymentMethod].icon
      return `<img src="${icon}">`
    },
  isFallbackSmartButtonVisible: (
    state,
    { numOfPaymentMethods, isWallet, isSmartFormPopin, isSmartFormForcedToken }
  ) => {
    const isCardsAvailable =
      state.smartForm?.availablePaymentMethods?.includes('CARDS')
    return (
      !isSmartFormPopin &&
      numOfPaymentMethods === 1 &&
      !isSmartFormForcedToken &&
      !isCardsAvailable &&
      !isWallet
    )
  },
  onlySmartButtons: (
    state,
    { getFormElement, getSmartFormElement, getSmartButtonElements }
  ) => {
    return (
      !getSmartFormElement() &&
      !getFormElement() &&
      getSmartButtonElements().length > 0
    )
  },
  isPopupRedirectionMethodActive: (
    state,
    { getRedirectionType, paymentMethod }
  ) => {
    return (
      !!paymentMethod &&
      ['popup', 'tab'].includes(getRedirectionType(paymentMethod))
    )
  },
  showOverlay: (
    state,
    { isSmartFormOpen, isApplePayActive, isExtrasFormVisible }
  ) => {
    const forcedOverlay = state.form.smartform.overlay.forcedDisplayState
    const { postPaymentRedirecting, redirectPopupOpen, processingPayment } =
      state
    const { activeMethod } = state.smartForm

    // Forced overlay status by the merchant will override any of our internal logic
    if (forcedOverlay !== null) return forcedOverlay

    return (
      isSmartFormOpen ||
      isExtrasFormVisible ||
      postPaymentRedirecting ||
      redirectPopupOpen ||
      processingPayment ||
      (!!activeMethod && !isApplePayActive)
    )
  },
  showOverlayMessage: (state, { isCardMethodActive, isApplePayActive }) => {
    const forcedOverlay = state.form.smartform.overlay.forcedDisplayState
    const { postPaymentRedirecting, redirectPopupOpen, processingPayment } =
      state
    const { activeMethod } = state.smartForm

    // If forced by the merchant always display the message
    if (forcedOverlay !== null) return true

    return (
      postPaymentRedirecting ||
      redirectPopupOpen ||
      processingPayment ||
      (!!activeMethod && !isCardMethodActive && !isApplePayActive)
    )
  },
  isSmartFormForcedToken: state =>
    isSmartFormForcedTokenHelper(state?.dna?.tokens),
  smartFormPaymentToken: state => state.dna.tokens?.smartForm?.[0],
  hasNavigatedFrom: state => view => {
    const { history } = state.smartForm.navigation
    return history[history.length - 2] === view
  },
  /**
   * When userPaymentMethodsOrder is set, return sorted copy of
   * availablePaymentMethods.
   *
   * Any methods missing in userPaymentMethodsOrder is placed at the end but
   * maintain their relative order with other unspecified methods.
   *
   * If CARDS or any unavailable method is specified, it is ignored and a
   * warning is displayed.
   *
   * @param {Object} state
   * @returns {string[]}
   * @since KJS-3552
   */
  sortedPaymentMethods: ({
    smartForm: { availablePaymentMethods: pm, userPaymentMethodsOrder: order }
  }) => {
    if (!order?.length) return pm

    const ignoredKeys = order.filter(
      key => !pm.includes(key) || key === 'CARDS'
    )

    if (ignoredKeys.length) {
      const list = ignoredKeys.join(', ')
      console.warn(`KR: the following payment methods will be ignored: ${list}`)
    }

    return pm.slice().sort((a, b) => {
      if (a === 'CARDS') return -1
      if (b === 'CARDS') return 1
      const idxA = order.includes(a) ? order.indexOf(a) : Infinity
      const idxB = order.includes(b) ? order.indexOf(b) : Infinity
      return idxA - idxB
    })
  },
  isNavigationOnContent: ({ smartForm }) => {
    return smartForm.navigation.currentView === 'content'
  },
  allCardBrandsFiltered: (
    { smartForm: { smartButtons } },
    { hasAnyCardBrand }
  ) => {
    return !hasAnyCardBrand || smartButtons.includes('CARDS')
  },
  isPaymentDone: ({ smartForm }) => smartForm.paymentDone,
  popinOpensMethodDirectly: (
    {},
    { numOfPaymentMethods, isWalletSmartForm, hasTokens }
  ) => numOfPaymentMethods === 1 && !(isWalletSmartForm && hasTokens),
  hasCardFormExpandedInside: (
    _state,
    { cardsFormExpanded, outsideCardsForm }
  ) => {
    return cardsFormExpanded && !outsideCardsForm
  },
  isGridMode: ({ smartForm }) => smartForm.layout === 'grid',
  renderGenericEmbeddedHeader: (
    { amount },
    {
      hasSeveralPaymentMethods,
      isWallet,
      cardsFormExpanded,
      hasCardMethodAvailable,
      outsideCardsForm
    }
  ) => {
    return (
      !!amount &&
      hasSeveralPaymentMethods &&
      (!cardsFormExpanded ||
        (cardsFormExpanded && isWallet) ||
        !hasCardMethodAvailable ||
        outsideCardsForm)
    )
  },
  hasOtherPaymentMethods: ({ smartForm: { availablePaymentMethods } }) => {
    return availablePaymentMethods.filter(pm => pm !== 'CARDS').length > 0
  },
  isWalletSmartFormOrRegister: (
    { amount },
    { isWalletSmartForm, hasTokens }
  ) => {
    return (isWalletSmartForm && hasTokens) || amount === 0
  },
  showOpmLabel: (
    _state,
    {
      isWalletSmartFormOrRegister,
      hasOtherPaymentMethods,
      hasCardFormExpandedInside
    }
  ) => {
    return (
      (isWalletSmartFormOrRegister && hasOtherPaymentMethods) ||
      hasCardFormExpandedInside
    )
  }
}
