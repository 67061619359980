import masker from '@/slave/components/mask/masker'
import tokens from '@/slave/components/mask/tokens'
import {
  deleteHandler,
  inputHandler
} from '@/slave/components/tools/ExpiryTools'
import { logSentryException } from '@/common/util/sentry'
import { formatLongExpiryDate } from '@/slave/util/format'

/**
 * All the logic relative to the edition and format of the fields
 */
export const PanInputEditionMixin = {
  mounted() {
    const el = this.$refs.input

    el.onkeydown = evt => {
      let position = el.selectionEnd
      let key = evt.which || evt.keyCode || 0

      if (this.needsHelp && !this.isUIWebView) {
        return this.helpedEdition(el, evt, this.cardPattern, tokens)
      } else {
        // [Remove]
        if (key === 8) {
          evt.preventDefault()
          // remove from the string the char behind the cursor
          let leftSlice = el.value.substring(0, position - 1)
          let rightSlice = el.value.substring(position)

          // If the joined string is equal to souce, we have delete a blank space, we remove another char
          if (
            leftSlice.length &&
            `${leftSlice}${rightSlice}`.replace(/\s/g, '') ===
              el.value.replace(/\s/g, '')
          ) {
            leftSlice = leftSlice.substring(0, leftSlice.length - 1)
            position -= 1
          }

          el.value = masker(
            `${leftSlice}${rightSlice}`,
            this.cardPattern,
            true,
            tokens
          )

          // if the char (prev) is blank space, reposition 1 before
          let nextPosition = position - 1
          let prevNextChar = el.value[nextPosition - 1]
          if (prevNextChar === ' ') nextPosition -= 1

          this.applyPosition(el, nextPosition)
        }
      }

      return true
    }

    el.oninput = evt => {
      /* We need to simulate, manually, the input event for ios behave testing.
       * Compares the string to avoid external emitters.
       */
      let isTest = evt['_args'] !== undefined ? evt['_args'][0] : false
      if (!evt.isTrusted && isTest !== 'behave' && isTest != 'scan') return // avoid infinite loop

      let position = el.selectionEnd
      let digit = el.value[position - 1]
      let positionForced = false

      /**
       * Pan custom logic
       *
       * At this block we update the position and/or value for
       * special usecases, every usecase should be documented and the
       * ones preventing the default event should be the first on the
       * stack
       */

      // Apply the mask
      el.value = this.applyMask(el.value)

      // Reposition the cursor
      if (!positionForced) {
        while (
          position < el.value.length &&
          el.value.charAt(position - 1) !== digit
        ) {
          position++
        }
      }

      this.applyPosition(el, position)
    }
  },
  methods: {
    applyMask(input) {
      if (!this.dna?.cards) return input
      // Get sanitized raw payment card number and apply length limitation
      this.detectBrandsFromNumber(input, this.networkSelectionMode)
      const fieldConf = this.dna.cards[this.detectedBrands[0]]?.fields['pan']
      const defConf = this.dna.cards.DEFAULT?.fields['pan']
      const maxLength = fieldConf?.maxLength || defConf?.maxLength
      if (!maxLength) return input
      const rawNumber = input.replace(/[^\d]/g, '').substring(0, maxLength)

      // Apply final mask with max
      let i = 0

      let cardPattern = '#### #### #### #### ###'
      if (this.detectedBrands[0] === 'AMEX') cardPattern = '#### ###### #####'

      return cardPattern
        .replace(/#/g, $1 => {
          const c = i < rawNumber.length ? rawNumber[i] : ''
          i += 1
          return c
        })
        .trim()
    }
  }
}

export const ExpiryDateInputEditionMixin = {
  data() {
    return {
      magic: false
    }
  },
  mounted() {
    const el = this.$refs.input

    el.onkeydown = evt => {
      try {
        let position = el.selectionEnd
        let key = evt.which || evt.keyCode || 0

        if (this.needsHelp && !this.isUIWebView) {
          return this.helpedEdition(el, evt, '!!/!!', tokens)
        } else {
          // Delete handling (only if deleting after first character)
          if (key == 8 && position > 0) {
            let [newString, newPosition] = deleteHandler(position, el.value)
            evt.preventDefault()
            el.value = masker(newString, '!!/!!', true, tokens)
            this.applyPosition(el, newPosition)
          }
        }

        // Do not let the space enter it
        if (key == 32) return evt.preventDefault()
      } catch (error) {
        // KJS-3749 - Unexpected error on Safari
        logSentryException(error, 'slave/components/mixins/InputEdition', {
          position,
          value: el.value
        })
      }
    }

    el.oninput = evt => {
      /* We need to simulate, manually, the input event for ios behave testing.
       * Compares the string to avoid external emitters.
       */
      let isTest = evt['_args'] !== undefined ? evt['_args'][0] : false
      if (!evt.isTrusted && isTest !== 'behave') return // avoid infinite loop

      // Preformat in case the format is MM/YYYY (Chrome autofill)
      el.value = formatLongExpiryDate(el.value)

      let position = el.selectionEnd
      let digit = el.value[position - 1]

      // Custom update
      let newString
      let newPosition
      ;[newString, newPosition] = inputHandler(
        position,
        el.value,
        digit,
        this.magic
      )

      let maskedValue = masker(
        newString ? newString : el.value,
        '!!/!!',
        true,
        tokens
      )

      el.value = maskedValue

      //console.log("applying position : " + `${newPosition} def ${position}`)
      this.applyPosition(el, newPosition ? newPosition : position)
    }
  }
}

export const SecurityCodeInputEditionMixin = {
  mounted() {
    const el = this.$refs.input

    el.onkeydown = evt => {
      let position = el.selectionEnd
      let key = evt.which || evt.keyCode || 0

      if (this.needsHelp && !this.isUIWebView) {
        return this.helpedEdition(el, evt, this.cvvPattern, tokens)
      } else {
        if (key === 8) {
          evt.preventDefault()
          // remove from the string the char behind the cursor
          let leftSlice = el.value.substring(0, position - 1)
          let rightSlice = el.value.substring(position)

          el.value = masker(
            `${leftSlice}${rightSlice}`,
            this.cvvPattern,
            true,
            tokens
          )

          this.applyPosition(el, position - 1)
        }
      }

      return true
    }

    el.oninput = evt => {
      /* We need to simulate, manually, the input event for ios behave testing.
       * Compares the string to avoid external emitters.
       */
      let isTest = evt['_args'] !== undefined ? evt['_args'][0] : false
      if (!evt.isTrusted && isTest != 'behave') return // avoid infinite loop

      let position = el.selectionEnd
      let digit = el.value[position - 1]

      el.value = masker(el.value, this.cvvPattern, true, tokens)

      // if the digit was changed, increment position until find the digit again
      while (
        position < el.value.length &&
        el.value.charAt(position - 1) !== digit
      ) {
        position++
      }

      this.applyPosition(el, position)
    }
  }
}
