<template lang="pug">
  SmartFormGrid(v-if="isGridMode")
    template(v-slot:cardForm="")
      slot
    template(v-slot:extra="")
      slot(name="extra")
  SmartFormLegacy(v-else)
    template(v-slot:cardForm="")
      slot
    template(v-slot:extra="")
      slot(name="extra")
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Events from '@/configuration/Events'

import SmartFormLegacy from '@/host/components/smartform/SmartFormLegacy'
import SmartFormGrid from '@/host/components/smartform/grid/SmartForm'

export default {
  name: 'SmartForm',
  components: {
    SmartFormLegacy,
    SmartFormGrid
  },
  computed: {
    ...mapGetters(['isGridMode', 'cardsFormExpanded', 'getFormElement'])
  },
  watch: {
    async isGridMode(val) {
      if (val) this.$el.parentElement.setAttribute('kr-grid', '')
      else this.$el.parentElement.removeAttribute('kr-grid')

      // Trigger the relocation actions
      await this.$nextTick()
      this.$bus.$emit(Events.iframe.relocation, { slaves: true })
    },
    cardsFormExpanded() {
      this.$bus.$emit(Events.krypton.form.relocation, {})
      this.$nextTick(() => {
        this.$bus.$emit(Events.iframe.relocation, { slaves: true })
        this.getFormElement().style.removeProperty('display') // Show (in case it's hidden)
      })
    }
  }
}
</script>
