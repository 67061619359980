export const wait = async time => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(null)
    }, time)
  })
}

export const nextTick = async () => {
  await wait(0)
}
