import _ from 'underscore'
import clientTranslations from '@/configuration/Translations'
import { ErrorTranslationsLoader } from '@/common/loader/errorTranslations'

export const getParentCulture = culture => {
  if (!_.isString(culture) || !/^[a-z]{2}(-[A-Z]{2})?/.test(culture)) {
    return null
  }

  let language = culture.split('-')[0]
  return `${language}-${language.toUpperCase()}`
}

const translate = (translations, translationKey, lang) => {
  // 1 - Better scenario: The key exists on the given language
  // Then the translation is returned to the caller.
  const translationSet = translations[lang]
  if (translationSet && translationKey in translationSet) {
    return translationSet[translationKey]
  }

  // 2 - Key is not defined on the language but on the parent culture
  // In this case, we know the key is not defined on the current
  // sent language, but we must check if the language has a parent culture
  // an this parent culture has the translation defined:
  const parentCulture = getParentCulture(lang)
  if (
    parentCulture &&
    parentCulture in translations &&
    translationKey in translations[parentCulture]
  ) {
    return translations[parentCulture][translationKey]
  }

  // 3 - Key is not on parent culture but on default language
  // At this point, the translation has not been found on the requested
  // language, nor on the parent culture, if the default language has
  // the key defined is returned
  const defaultTransSet = translations['en-EN']
  if (translationKey in defaultTransSet) {
    return defaultTransSet[translationKey]
  }

  // 4 - No translation found
  // At this point we dont have more cascade usecases to return the
  // translated key. We return the key
  return translationKey
}

export const translationGetters = async app => {
  const translations = clientTranslations
  const errorLoader = new ErrorTranslationsLoader(translations)

  // For the ghost app and unit test env, load the translations during the setup
  if (app === 'ghost') errorLoader.load()
  // For unit test env, load the translations during the setup and wait to continue
  if (app !== 'slave' && global.unitTest) {
    await errorLoader.load(false)
  }

  return {
    onErrorTranslationLoaded:
      state =>
      (callback = _.noop) => {
        return new Promise((resolve, reject) => {
          if (state.isUnitaryTest) {
            callback()
            resolve()
          }
          if (app !== 'slave')
            return errorLoader.load().then(() => {
              callback()
              resolve()
            })
        })
      },
    translate: state => key => {
      if (app !== 'slave' && !global.unitTest) {
        errorLoader.load()
      }

      const lang = state.language
      const translation = translate(translations, key, lang)

      if (~translation.indexOf('%brand%')) {
        const formId = state.error.formId ?? state.forms[state.activeForm]
        let unfilteredBrands = state[`cardForm_${formId}`].unfilteredBrands || [
          'DEFAULT'
        ]
        unfilteredBrands = !unfilteredBrands.length
          ? ['DEFAULT']
          : unfilteredBrands
        const brand = unfilteredBrands[0].toLowerCase().replace('_', ' ')
        return translation.replace('%brand%', brand)
      }

      return translation
    }
  }
}
