module.exports = {
  fallback: 'en-EN',
  alias: {
    es: 'es-ES',
    fr: 'fr-FR',
    pt: 'pt-PT',
    en: 'en-EN',
    de: 'de-DE',
    it: 'it-IT',
    ja: 'ja-JP',
    nl: 'nl-NL',
    pl: 'pl-PL',
    ru: 'ru-RU',
    sv: 'sv-SE',
    tr: 'tr-TR',
    zh: 'zh-CN'
  }
}
