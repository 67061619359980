<template lang="pug">
  .kr-wallet-group
    SmartFormGridWalletButton(
      v-for="conf in methods"
      :key="conf.token"
      :method="conf"
      v-on:selected="onSelected"
      :locked="allLocked"
    )
</template>

<script>
import SmartFormGridWalletButton from '@/host/components/smartform/grid/wallet/Button'

export default {
  name: 'SmartFormGridWalletGroup',
  components: {
    SmartFormGridWalletButton
  },
  props: {
    methods: {
      type: Array,
      required: true
    },
    allLocked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onSelected(method) {
      this.$emit('selected', method)
    }
  }
}
</script>
