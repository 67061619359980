import GridConfigMixin from '@/host/components/mixins/grid/Config'

/**
 * Mixin for grid button computation
 */
export default {
  mixins: [GridConfigMixin],
  props: {
    groupMethods: {
      type: Number,
      default: 1
    },
    groupPosition: {
      type: Number,
      default: 0
    }
  },
  computed: {
    gridStyles() {
      const computeTrigger = this.forcedCompute
      return {
        gridColumnStart: `${this.getColumnStart()}`
      }
    },
    isOnTheLastRow() {
      const computeTrigger = this.forcedCompute
      const { width, gap } = this.getGridElementSizes()
      if (this.groupMethods <= this.computeColumns(width, gap)) return true
      const remainder = this.groupMethods % this.computeColumns(width, gap)
      return this.groupPosition + 1 > this.groupMethods - remainder
    }
  },
  methods: {
    getColumnStart() {
      if (this.justify === 'start') return 'auto'

      // justify stretch
      const { width, gap } = this.getGridElementSizes()
      const columns = this.computeColumns(width, gap)
      const remainder = this.groupMethods % columns
      const fitsOnGrid = !(columns % remainder)
      // Fits on grid and it's not the last row - auto
      if (fitsOnGrid && !this.isOnTheLastRow) return 'auto'

      // Does not fit on grid and it's not the last row - default span
      const resCols = columns * remainder
      if (!fitsOnGrid && !this.isOnTheLastRow)
        return `span ${resCols / columns}`

      // Fits on grid and it's the last row
      if (!remainder) return `auto` // Matches the number of columns
      // Adjusted - if it fits with the cols (no decimals)
      if (fitsOnGrid) return `span ${columns / remainder}`
      // Adjusted with the resCols - should never have decimals
      return `span ${resCols / remainder}`
    },
    getGridElementSizes() {
      const $wrapper = this.$el?.closest('.kr-grid-group')
      return {
        width: $wrapper?.clientWidth ?? 0,
        gap: getComputedStyle($wrapper)?.gap.replace('px', '') || 0
      }
    }
  }
}
