import { logSentry } from '@/common/util/sentry'
import payment from '@/common/util/payment'
import AbstractTrigger from '@/ghost/service/Triggers/AbstractTrigger'
import Events from '@/configuration/Events'
import ProcessState from '@/ghost/workflow/payment/state/ProcessState'

/**
 * This class handles the reception of the Pay store action
 * from any of the iframes and handles validation on the ghost
 * and executing the workflow
 *
 * React to krypton.message.pay event
 *
 */
export default class PaymentTrigger extends AbstractTrigger {
  constructor($locator) {
    super($locator, 'pay')
    this.iframeHandler = $locator.iframeHandler
  }

  /**
   * Payment action
   *
   * @method onEvent
   * @param {Action_Pay} payAction Pay store action
   */
  async onEvent(payAction) {
    const { formId, googlePayData } = payAction
    try {
      await super.onEvent(payAction)
      // Register the workflow
      const workflow = this.$locator.ghost.registerWorkflow(formId)
      workflow.payAction = payAction

      let formObj
      if (googlePayData) {
        this.paymentMethod = 'GOOGLEPAY'
        formObj = googlePayData.formObj
        workflow.changeState(new ProcessState(this.$locator))
      } else {
        await workflow.validate(true, false, payAction.extra)

        // Format the data
        formObj = await workflow.format(this.endpoint)
        logSentry('Payment', 'Validation ok')
      }
      // Process the payment
      const billingOrRedirect = await workflow.processPayment(formObj)

      // Separates common payment and redirection
      // Add extra fields to billingOrRedirect object
      billingOrRedirect.extra = payAction.extra
      billingOrRedirect.formId = payAction.formId
      await this.processBillingOrRedirect(billingOrRedirect)

      return Promise.resolve()
    } catch (error) {
      this.onError(error, formId)
    }
  }

  async onError(error, formId, path = 'ghost/service/PaymentTrigger.onEvent') {
    super.onError(error, formId, path)
  }

  processBillingOrRedirect(billingOrRedirect) {
    if (billingOrRedirect.is('billingTransaction')) {
      // If the billing is not valid, reject
      if (!billingOrRedirect.isValid())
        return Promise.reject({
          errorCode: 'CLIENT_992',
          metadata: { answer: JSON.stringify(billingOrRedirect) }
        })

      // Transaction - send the data to the payment handler
      payment.handler(billingOrRedirect.json(), this.$locator)

      // Set form token to consumed to avoid multiple payment tries
      this.$store.dispatch('update', { formTokenConsumed: true })

      logSentry('Payment', 'Payment done')
      return Promise.resolve()
    } else if (billingOrRedirect.is('billingRedirect')) {
      // Its a redirect: 3dsecure
      logSentry('Payment', '3ds v1')
      this.$bus.$emit(Events.krypton.payment.redirect, billingOrRedirect)
      return Promise.resolve()
    } else if (billingOrRedirect.is('billingRedirectV2')) {
      // Its a V2 redirect: 3dsecure
      logSentry('Payment', '3ds v2')
      this.$bus.$emit(Events.krypton.payment.redirect, billingOrRedirect)
      return Promise.resolve()
    } else if (billingOrRedirect.is('billingSplitPayment')) {
      this.proxy.send(billingOrRedirect)
      return Promise.resolve()
    } else {
      return Promise.reject({
        errorCode: 'CLIENT_992',
        metadata: { answer: JSON.stringify(billingOrRedirect) }
      })
    }
  }

  /**
   * Create the final object "Payment" to report to the
   * host window
   */
  factoryFinalBillingObject(billingStore) {
    if (!billingStore.isValid()) {
      return Promise.reject({})
    } else {
      return Promise.resolve(billingStore.json())
    }
  }
}
