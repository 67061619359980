import { uniqueId } from 'underscore'
import Events from '@/configuration/Events'
import appLoader from '@/common/loader/app'

/** COMPONENTS */
import KryptonTabHandler from '@/host/components/KryptonTabHandler'
import KryptonCardForm from '@/host/components/controls/CardForm'
import KryptonFormError from '@/host/components/controls/KryptonFormError'
import KryptonDiscountPanel from '@/host/components/controls/KryptonDiscountPanel'
import KryptonPaymentButton from '@/host/components/controls/KryptonPaymentButton'
import KryptonBrandButtons from '@/host/components/controls/KryptonBrandButtons'
import SimpleModal from '@/host/components/controls/SimpleModal'
import KryptonLayerManager from '@/host/components/controls/KryptonLayerManager'
import KryptonPopinHeaderWrapper from '@/host/components/popin/KryptonPopinHeaderWrapper'
import KryptonPopinFooter from '@/host/components/popin/KryptonPopinFooter'
import KryptonInstallments from '@/host/components/fields/KryptonInstallments'
import KryptonFirstInstallmentDelay from '@/host/components/fields/KryptonFirstInstallmentDelay'
import KryptonDoRegister from '@/host/components/fields/KryptonDoRegister'
import KryptonIdentityDocType from '@/host/components/fields/KryptonIdentityDocType'
import KryptonIdentityDocNumber from '@/host/components/fields/KryptonIdentityDocNumber'
import KryptonCardHolderName from '@/host/components/fields/KryptonCardHolderName'
import KryptonCardHolderMail from '@/host/components/fields/KryptonCardHolderMail'
import KryptonField from '@/host/components/fields/KryptonField'
import KryptonLayer from '@/host/components/controls/KryptonLayer'
import KryptonCurrencyConversion from '@/host/components/controls/CurrencyConversion'
import KryptonWalletTabs from '@/host/components/wallet/KryptonWalletTabs'
import KryptonWalletCardList from '@/host/components/wallet/KryptonWalletCardList'
import SmartForm from '@/host/components/smartform/SmartForm'
import FallbackSmartButton from '@/host/components/smartbutton/FallbackSmartButton'
import SmartButtonError from '@/host/components/smartbutton/Error'
import SmartFormCardHeader from '@/host/components/smartform/CardHeader'
import Skeleton from '@/host/components/smartform/Skeleton'
import ExtrasForm from '@/host/components/extrasform/ExtrasForm'
import SmartFormSinglePaymentButton from '@/host/components/smartform/SinglePaymentButton'

/** MIXINS */
import { GlobalThemeMixin } from '@/host/components/mixins/GlobalTheme'

export default locator => {
  return ({ baseAddress, element: $el }) => {
    const identifier = uniqueId('krvue-')
    $el.attr('krvue', identifier)

    if ($el.hasClass('kr-smart-form'))
      $el.find('.kr-embedded').attr('krvue', identifier)

    return appLoader(locator, $el, {
      el: `[krvue="${identifier}"]`,
      provide: {
        context: {}
      },
      components: {
        KryptonCardForm,
        KryptonField,
        KryptonFormError,
        KryptonInstallments,
        KryptonFirstInstallmentDelay,
        KryptonIdentityDocType,
        KryptonIdentityDocNumber,
        KryptonCardHolderName,
        KryptonCardHolderMail,
        KryptonDiscountPanel,
        KryptonPaymentButton,
        KryptonPopinHeaderWrapper,
        KryptonPopinFooter,
        KryptonBrandButtons,
        KryptonDoRegister,
        KryptonWalletTabs,
        KryptonWalletCardList,
        SimpleModal,
        KryptonLayerManager,
        KryptonTabHandler,
        KryptonLayer,
        KryptonCurrencyConversion,
        SmartForm,
        FallbackSmartButton,
        SmartButtonError,
        SmartFormCardHeader,
        Skeleton,
        ExtrasForm,
        SmartFormSinglePaymentButton
      },
      mixins: [GlobalThemeMixin],
      created() {
        this.$store.dispatch('update', { baseAddress })
      },
      mounted() {
        this.$el.setAttribute('kr-resource', '')
        this.$bus.$emit(Events.krypton.vue.ready)
      }
    })
  }
}
