import AbstractTrigger from '@/ghost/service/Triggers/AbstractTrigger'
import Events from '@/configuration/Events'

export default class GetPayloadInfosTrigger extends AbstractTrigger {
  constructor($locator) {
    super($locator, 'getPayloadInfos')
    this.browserRequests = $locator.browserRequests
  }

  async onEvent({ payload, testMode, payloadType }) {
    try {
      await super.onEvent({ payload, testMode, payloadType })
      const paymentToken =
        (payloadType === 'GOOGLEPAY' ? payload : payload?.token) ?? {}

      if (!this.$store.getters.extraFieldsArePossible) {
        // Trigger appleOrGooglePay payment action, no extra fields required
        this.$bus.$emit(Events.krypton.message.appleOrGooglePayPayment, {
          paymentToken,
          payloadType
        })
        return
      }
      const res = await this.callGetPayloadInfos(payload, testMode, payloadType)

      if (this.isValid(res)) {
        const { brands, bin, country } = res.response.answer

        // If there are dynamic installments we need to call setBin to get the proper binOptions
        if (this.$store.state.hasDynamicValues) {
          this.$store.dispatch(
            `cardForm_${this.$store.state.forms.main}/setBin`,
            bin
          )
        }

        // Get the selected brand or the first one
        const brand = this.getSelectedBrand(brands)

        // Setup the extras form
        this.$store.dispatch('setupExtrasForm', { brand, country })
        if (!this.$store.getters.extrasFormHasData) {
          // Trigger apple pay payment action, no extra fields required
          this.$bus.$emit(Events.krypton.message.appleOrGooglePayPayment, {
            paymentToken,
            payloadType
          })
        } else {
          // Send the payment token data to the AppleOrGooglePayPaymentTrigger
          this.$bus.$emit(Events.ghost.appleOrGooglePay.paymentToken, {
            paymentToken
          })
          // Close the modal (Google or Apple)
          this.closeModal(payloadType, testMode)
          // must set the brand to update installments select content
          this.$store.dispatch('updateActiveForm', { selectedBrand: brand })
          // The payment should be triggered from the modal
          this.$store.dispatch('showExtrasForm')
        }
      } else {
        this.onError(res.response.answer, payloadType)
      }
    } catch (error) {
      this.onError(error, payloadType)
    }
  }

  onError(error, payloadType, path = 'ghost/service/GetPayloadInfos.onEvent') {
    error.paymentMethod = payloadType
    super.onError(error, path)
    this.$store.dispatch('unselectMethod')
    this.proxy.send(this.storeFactory.create('getPayloadInfosError'))
  }

  /**
   * Call the get payload infos WS
   * @param {Object} payload
   * @param {Boolean} testMode
   * @param {'APPLE_PAY' | 'GOOGLEPAY'} payloadType
   * @returns {Promise} response
   * e.g.
   * { response: { answer: { brands: ['VISA'] } } }
   */
  async callGetPayloadInfos(payload, testMode, payloadType = 'APPLE_PAY') {
    const { dna, publicKey, remoteId } = this.$store.state
    const objectData =
      payloadType === 'GOOGLEPAY'
        ? {
            payload,
            publicKey,
            payloadType,
            agreementUuid: dna.smartForm.GOOGLEPAY?.metadata?.agreementUuid
          }
        : {
            agreementUuid:
              dna.smartForm.APPLE_PAY.metadata.mids[0].agreementUuid,
            publicKey,
            payload: testMode
              ? this.$store.getters.getApplePayTestPayload
              : payload,
            payloadType
          }
    const url = this.restAPI.addJSessionID(
      this.restAPI.getGetApplePayPayloadInfos(this.endpoint)
    )
    const requestData = this.storeFactory.create('requestData', {
      url,
      objectData,
      headers: { remoteId },
      options: {}
    })
    return await this.browserRequests.post(requestData)
  }

  isValid(res) {
    return !(res.response.status === 'ERROR' || res.response.answer?.errorCode)
  }

  closeModal(payloadType, appleTestMode) {
    switch (payloadType) {
      case 'APPLE_PAY':
        this.closeApplePayModal(appleTestMode)
        break
      case 'GOOGLEPAY':
        this.closeGooglePayModal()
        break
    }
  }

  /**
   * Close the apple pay modal (real or simulator)
   * @param {Boolean} testMode
   */
  closeApplePayModal(testMode) {
    if (testMode) this.$store.dispatch('closeApplePaySimulatorModal')
    else this.proxy.send(this.storeFactory.create('applePayPaymentVerified'))
  }

  closeGooglePayModal() {
    if (this.$store.state.googlePay.simulator.open)
      this.$store.dispatch('closeGooglePaySimulator')
  }

  /**
   * Get the selected brand or the first one
   * @param {Array} brands
   * @returns {String} brand
   */
  getSelectedBrand(brands) {
    const brandIntersection = Object.keys(this.$store.state.dna.cards).filter(
      brandName => brands.includes(brandName)
    )

    return brandIntersection.length === 0 ? brands[0] : brandIntersection[0]
  }
}
