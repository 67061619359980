import { mapState } from 'vuex'
import GridConfigMixin from '@/host/components/mixins/grid/Config'

/**
 * Mixin for grid group computation
 */
export default {
  mixins: [GridConfigMixin],
  data() {
    return {
      visibleLabels: {}
    }
  },
  computed: {
    ...mapState({
      hideLabelForced: ({ smartForm }) =>
        !smartForm.otherPaymentMethods.showLabel
    }),
    groupMethods() {
      return this.filteredMethods.length
    },
    gridStyles() {
      const computeTrigger = this.forcedCompute
      return {
        gridTemplateColumns: `repeat(${this.getGridColumns()}, 1fr)`
      }
    },
    showLabels() {
      // Only main view label visibility can be forced
      if (this.hideLabelForced && this.type === 'main') return false
      return Object.entries(this.visibleLabels).every(([i, value]) => value)
    }
  },
  watch: {
    methods: {
      handler() {
        this.setupVisibleLabels()
      },
      deep: true
    }
  },
  mounted() {
    this.setupVisibleLabels()
  },
  methods: {
    getGridColumns() {
      const { width, gap } = this.getGridElementSizes()
      const columns = this.computeColumns(width, gap)
      if (this.justify === 'start') return columns
      // justify stretch
      const remainder = this.groupMethods % columns
      const fitsOnGrid = !(columns % remainder)
      if (fitsOnGrid) return columns
      return columns * remainder
    },
    // Setup list of methods
    setupVisibleLabels() {
      this.visibleLabels = {}
      for (const method of this.methods) {
        this.$set(this.visibleLabels, method.key, true)
      }
    },
    // Updates the visibility state of the method (enough space for label or not)
    updateVisibleLabels(method, show) {
      this.$set(this.visibleLabels, method, show)
    },
    getGridElementSizes() {
      return {
        width: this.$el?.clientWidth ?? 0,
        gap: getComputedStyle(this.$el)?.gap.replace('px', '') || 0
      }
    }
  }
}
