import { mapGetters, mapState } from 'vuex'

import SmartFormRadioButton from '@/host/components/smartform/RadioButton'
import BrandIcon from '@/host/components/controls/BrandIcon'
import KryptonLoadingAnimation from '@/host/components/controls/KryptonLoadingAnimation'

import { SmartFormClickMixin } from '@/host/components/mixins/SmartFormClick'
import { FlashWarningSubscriberMixin } from '@/host/components/mixins/FlashWarningSubscriber'

export default {
  components: {
    SmartFormRadioButton,
    BrandIcon,
    KryptonLoadingAnimation
  },
  mixins: [SmartFormClickMixin, FlashWarningSubscriberMixin],
  provide() {
    return {
      context: this.context
    }
  },
  props: {
    locked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hover: false,
      context: {
        formId: null,
        namespace: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'isSelectedMethod',
      'isActiveMethod',
      'isSinglePaymentButton',
      'paymentMethodMetadata',
      'cardsFormExpanded'
    ]),
    ...mapState({
      radioButtonConfig: state => state.form.smartform.radioButton,
      buttonConfig: state => state.form.smartform.embedded.button
    }),
    styles() {
      const styles = {}
      styles.button = {}
      if (this.hover) {
        styles.button.border = this.buttonConfig.$hover.border
      }
      return styles
    },
    dynamicClasses() {
      return {
        'kr-method-btn--spbtn': this.isSinglePaymentButton,
        'kr-method-btn--selected': this.isSelected,
        'kr-method-btn--warning': this.applyWarningStyle,
        'kr-method-btn--hover': this.hover,
        'kr-method-btn--disabled': this.isDisabled,
        'kr-method-btn--loading': this.isLoading
      }
    },
    hasRadioButton() {
      return (
        this.isSinglePaymentButton && this.radioButtonConfig.visibility === true
      )
    }
  },
  created() {
    const { forms } = this.$store.state
    if (this.cardsFormExpanded) {
      this.context.formId = forms.clone
    } else {
      this.context.formId = forms.main
    }
    this.context.namespace = `cardForm_${this.context.formId}`
  }
}
