import { getTokenHelper } from '@/host/service/DNA/Helper/wallet.js'

export const getWalletState = () => {
  return {
    paymentMethodToken: null
  }
}

export const walletActions = ($locator, app) => {
  const { storeFactory, proxy } = $locator
  return {
    deleteToken({}, { paymentMethodToken, method, formId }) {
      const deleteToken = storeFactory.create('deleteToken', {
        formId,
        paymentMethodToken,
        method
      })
      proxy.send(deleteToken)
    },
    startMethodWalletPayment({}, { paymentMethodToken, method }) {
      commit('UPDATE', {
        smartForm: {
          activeMethod,
          activeMethodMetadata: paymentMethodToken ?? null
        }
      })
      dispatch('showWindow', method)
    },
    startWalletPayment(
      { dispatch, state, getters },
      { method, paymentMethodToken }
    ) {
      const { cardsFormExpanded, isCardMethod } = getters
      const { forms } = state
      const formId = cardsFormExpanded ? forms.clone : forms.main
      if (isCardMethod(method)) {
        dispatch(`cardForm_${formId}/startWalletPayment`, paymentMethodToken)
      } else {
        dispatch('startMethodWalletPayment', {
          paymentMethodToken,
          method,
          formId
        })
      }
    }
  }
}
export const walletMutations = {}
export const walletGetters = {
  isWallet: ({ formMode, dna }, { hasTokens }) =>
    formMode === 'wallet' || (hasTokens && !dna.tokens.forced),
  isWalletSmartForm: (state, getters) => {
    const { formMode } = state
    const { isSmartForm } = getters
    return formMode === 'wallet' && isSmartForm
  },
  getTokens: ({ dna }) => {
    const cardTokens = dna?.tokens?.cards || []
    const smartFormTokens = dna?.tokens?.smartForm || []
    return [...cardTokens, ...smartFormTokens]
  },
  hasTokens: ({}, { getTokens }) => getTokens.length > 0,
  hasToken:
    ({}, { getToken }) =>
    token =>
      !!getToken(token),
  hasCardToken:
    ({ dna }) =>
    token => {
      const cardTokens = dna?.tokens?.cards || []
      return cardTokens.find(conf => conf.token === token)
    },
  hasCardTokens: ({ dna }) => {
    return Array.isArray(dna?.tokens?.cards) && dna.tokens.cards.length > 0
  },
  getToken:
    ({}, { getTokens }) =>
    token =>
      getTokenHelper(getTokens, token)
}
