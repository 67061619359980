import { dynamicMapState, dynamicMapActions } from '@/common/util/store'
import Events from '@/configuration/Events'

/** COMPONENTS */
import KryptonIcon from '@/host/components/controls/KryptonIcon'
import KryptonHelpButton from '@/host/components/controls/KryptonHelpButton'

/** MIXINS */
import { FieldMixin } from '@/host/components/mixins/Field'
import { ShakeMixin } from '@/host/components/mixins/Shake'
import { TestCardMixin } from '@/host/components/mixins/TestCard'
import { PaymentTokenMixin } from '@/host/components/mixins/PaymentToken'
import { EventsMixin } from '@/host/components/mixins/Events'
import { ClearMixin } from '@/host/components/mixins/Clear'

export const TextFieldMixin = {
  components: { KryptonIcon, KryptonHelpButton },
  mixins: [
    FieldMixin,
    ShakeMixin,
    TestCardMixin,
    PaymentTokenMixin,
    EventsMixin,
    ClearMixin
  ],
  data() {
    return {
      fieldValue: '',
      fieldType: 'text',
      hasFocus: false,
      autocomplete: ''
    }
  },
  computed: {
    ...dynamicMapState('context.namespace', ['nonSensitiveValues']),
    placeholder() {
      if (this.formPlaceholder) return this.formPlaceholder
      return this.translate(`label_${this.domFieldName.replace(/\-/g, '_')}`)
    }
  },
  watch: {
    ...dynamicMapActions('context.namespace', ['validateField']),
    visibility(isVisible) {
      // Hiding
      if (!isVisible && this.fieldValue.length) {
        this.fieldValue = ''
      }
    },
    clearFromMixin(newVal) {
      if (!newVal) return
      this.fieldValue = ''
      this.clearFromMixin = false
    },
    fieldValue(newVal) {
      if (this.fieldName === 'cardHolderMail') this.cleanValue()
      this.updateNonSensitiveValue({ [this.fieldName]: newVal })
      this.validateField(this.fieldName)
      this.validate()
    },
    nonSensitiveValues: {
      deep: true,
      handler() {
        const value = this.nonSensitiveValues[this.fieldName]
        if (value) this.fieldValue = value
      }
    }
  },
  methods: {
    setInput() {
      this.$input = this.$el.querySelector('input.kr-input-field')
    },

    onInput() {
      this.$bus.$emit(Events.krypton.field.length, {
        formId: this.context.formId,
        name: this.fieldName,
        length: this.fieldValue.length
      })
    }
  }
}
