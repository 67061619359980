var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "kr-method-label",
      class: _vm.dynamicClasses,
      style: _vm.styles.button,
      attrs: { type: "button", "kr-payment-method": _vm.method.toLowerCase() },
      on: {
        click: function ($event) {
          return _vm.openMethod(_vm.method)
        },
      },
    },
    [
      _vm.hasRadioButton
        ? _c("SmartFormRadioButton", {
            attrs: { checked: _vm.isSelected, hover: _vm.hover },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "kr-method-label-wrapper" },
        [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isCardMethod(_vm.method),
                expression: "!isCardMethod(method)",
              },
            ],
            staticClass: "kr-method-icon",
            domProps: { innerHTML: _vm._s(_vm.methodIconContent) },
          }),
          !_vm.isCardMethod(_vm.method)
            ? _c("label", [
                _vm._v(
                  _vm._s(
                    _vm.getCustomPaymentMethodLabel(_vm.method) ||
                      _vm.methodLabel
                  )
                ),
              ])
            : _c("SmartFormCardIcons", {
                attrs: { margin: 5, brands: _vm.cardBrands },
              }),
          !_vm.loadingAnimation && !_vm.locked
            ? _c("div", {
                staticClass: "kr-arrow-right",
                domProps: { innerHTML: _vm._s(_vm.rightArrow) },
              })
            : _vm.loadingAnimation
            ? _c("KryptonLoadingAnimation")
            : _vm._e(),
          _c("SmartFormMethodBadge", { attrs: { method: _vm.method } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }