import AbstractTrigger from '@/ghost/service/Triggers/AbstractTrigger'
import payment from '@/common/util/payment'
import Events from '@/configuration/Events'

/**
 * This class handles the reception of the Pay store action
 * from any of the iframes and handles validation on the ghost
 * and executing the workflow
 *
 * React to krypton.message.redirect event
 */
export default class RedirectTrigger extends AbstractTrigger {
  constructor($locator) {
    super($locator, 'redirect')
    this.iframeHandler = $locator.iframeHandler
    this.browserRequests = $locator.browserRequests
    this.restAPI = $locator.restAPI
    this.paymentMethod = null
  }

  /**
   * Payment action
   *
   * @method onEvent
   * @param {Action_Pay} redAction Pay store action
   */
  async onEvent(redAction) {
    try {
      await super.onEvent(redAction)
      this.paymentMethod = redAction.paymentMethod
      const { formToken, publicKey, smartForm, language, remoteId } =
        this.$store.state
      const { getRedirectionType, getPaymentMethodCloseButtonType } =
        this.$store.getters

      const url = this.restAPI.addJSessionID(
        this.restAPI.getProcessPaymentUrl(this.endpoint)
      )

      const { response } = await this.browserRequests.post(
        this.storeFactory.create('requestData', {
          url,
          objectData: {
            language,
            formToken,
            publicKey,
            paymentForm: {
              method: redAction.paymentMethod
            },
            displayMode: smartForm.methodDisplayMode,
            remoteId,
            clientVersion: this.restAPI.getClientVersion(),
            device: this.restAPI.getDeviceData(),
            wsUser: this.restAPI.getWSUser(),
            paymentMethodCloseButton: getPaymentMethodCloseButtonType
          },
          headers: {},
          options: {}
        })
      )

      // Check if there the response is an error
      if (response.answer.errorCode) throw response.answer

      this.$store.dispatch('openMethod', redAction.paymentMethod)

      // Redirection type [popin or popup]
      const redirectionType = getRedirectionType(redAction.paymentMethod)
      this.$store.dispatch('update', { redirectionType })

      const redirectStore = this.storeFactory.create('billingRedirect', {
        width: 390,
        height: 434,
        redirectUrl: response.answer.redirectUrl,
        forceFullscreen: redAction.forceFullscreen,
        isSmartPayment: redAction.isSmartPayment
      })
      redirectStore.formId = redAction.formId
      this.$bus.$emit(Events.krypton.payment.redirect, redirectStore)

      payment.messageListener(this.$locator, () => {}, true)
      return Promise.resolve()
    } catch (error) {
      this.onError(error)
    }
  }

  async onError(error, path = 'ghost/service/RedirectTrigger.onEvent') {
    super.onError(error, path)
  }
}
