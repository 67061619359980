import { getSupported } from '@/common/util/i18n'
import defaultState from '@/store/state'
import { getErrorState } from '@/store/modules/error'
import { v4 } from 'uuid'
import { intersection, without } from 'underscore'

import { securityReview } from '@/common/util/security'
import Events from '@/configuration/Events'
import { formActions } from '@/store/modules/form'
import { popinActions } from '@/store/modules/popin'
import { iosActions } from '@/store/modules/ios'
import { cssActions } from '@/store/modules/css'
import { redirectActions } from '@/store/modules/redirect'
import { errorActions } from '@/store/modules/error'
import { environmentActions } from '@/store/modules/environment'
import { smartFormActions } from '@/store/modules/smartform'
import { navigationActions } from '@/store/modules/navigation'
import { windowActions } from '@/store/modules/window'
import { internalsActions } from '@/store/modules/internals'
import { applePayActions } from '@/store/modules/applePay'
import { googlePayActions } from '@/store/modules/googlePay'
import { toolbarActions } from '@/store/modules/toolbar'
import { amountActions } from '@/store/modules/amount'
import { extrasFormActions } from '@/store/modules/extrasForm'
import { domActions } from '@/store/modules/dom'
import { callbackActions } from '@/store/modules/callback'
import { walletActions } from '@/store/modules/wallet'
import { binOptionsActions } from '@/store/modules/binOptions'
import { splitPaymentActions } from '@/store/modules/splitPayment'
import { layoutActions } from '@/store/modules/layout'

// root actions
export default ($locator, app) => {
  const actions = {
    ...{
      update({ commit, dispatch, state }, data) {
        if ('language' in data) data.language = getSupported(data.language)
        commit('UPDATE', data)
        // Language update - reset error/warn
        if ('language' in data) {
          if (state.error.errorCode) dispatch('error', state.error)
          if (state.warning.errorCode) dispatch('updateWarningMessage')
        }
        // Need to have DNA and testKeys set + either dna or testKeys in the update object
        if (state.dna && state.testKeys && (data.dna || data.testKeys))
          dispatch('loadToolbar')
      },
      force_update({ commit }, data) {
        commit('F_UPDATE', data)
      },
      sync({ commit }, data) {
        commit('SYNC', data)
      },
      addInterceptor({ commit, state }, { name, promise }) {
        const interceptors = state.interceptors
        interceptors[name].push(promise)
        commit('UPDATE', { interceptors })
      },
      async intercept({ state }, { name, args = [] }) {
        if (state.interceptors[name]?.length) {
          for (const interceptor of state.interceptors[name]) {
            await interceptor(...args)
          }
        }
      },
      appLoadTimeData({ commit }, appLoadTimeData) {
        appLoadTimeData.sort((a, b) => {
          return b.time - a.time
        })
        commit('UPDATE', { appLoadTimeData })
      },
      synced({ commit, dispatch }) {
        securityReview(dispatch)
        commit('SYNCED')
      },
      reset({ commit }) {
        commit('RESET', defaultState())
      },
      paymentStart({ commit }) {
        commit('UPDATE', {
          processingPayment: true,
          remoteId: null,
          serverDateTime: null
        })
      },
      paymentEnd({ commit, dispatch, getters }) {
        if (!getters.isCardMethodActive && !getters.isExtrasFormVisible)
          dispatch('closeMethod')
        commit('UPDATE', { processingPayment: false })
      },
      setRemoteId({ commit, state }) {
        if (state.remoteId) return
        commit('UPDATE', { remoteId: v4().split('-').join('') })
      },
      setToken({ commit, dispatch, getters }, formToken) {
        const { isPaymentDone } = getters // Get the value before the reset
        // Global values to reset when a new token is set
        commit('UPDATE', {
          formTokenConsumed: false,
          button: { disabled: false },
          error: getErrorState().error,
          transactions: getErrorState().transactions,
          remoteId: null,
          serverDateTime: null,
          formToken,
          parsingToken: true,
          disabledForm: false,
          smartForm: { paymentDone: false }
        })
        dispatch('forEachForm', [
          'update',
          {
            invalidFields: [],
            disabledForm: false,
            disabledCardForm: false
          }
        ])
        // If the payment has already been done, reset the payment data
        if (isPaymentDone) {
          dispatch('resetApplePay')
          dispatch('forEachForm', ['forceFieldClear'])
        }
      }
    },
    ...formActions($locator, app),
    ...popinActions($locator, app),
    ...iosActions,
    ...cssActions,
    ...redirectActions($locator, app),
    ...errorActions,
    ...environmentActions,
    ...smartFormActions($locator, app),
    ...navigationActions($locator),
    ...windowActions($locator, app),
    ...internalsActions,
    ...applePayActions($locator, app),
    ...googlePayActions($locator, app),
    ...toolbarActions($locator, app),
    ...amountActions($locator, app),
    ...extrasFormActions($locator, app),
    ...domActions($locator),
    ...callbackActions($locator, app),
    ...walletActions($locator, app),
    ...binOptionsActions($locator, app),
    ...splitPaymentActions($locator, app),
    ...layoutActions($locator, app)
  }

  // Use proxy events as actions
  registerActionEvents($locator, actions)

  return actions
}

// Allows to call an action from a different application by using the proxy events
const registerActionEvents = ($locator, actions) => {
  const actionEvents = without(
    intersection(Object.keys(Events.krypton.message), Object.keys(actions)),
    'sync'
  )
  for (const event of actionEvents) {
    $locator.$bus.$on(Events.krypton.message[event], msg => {
      $locator.$store.dispatch(event, msg)
    })
  }
}
