import _ from 'underscore'
import { mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'

export const PaymentTokenMixin = {
  inject: ['context'],
  computed: {
    ...mapState(['formMode']),
    ...mapState({
      dnaCards: state => state.dna?.tokens?.cards || []
    }),
    ...dynamicMapState('context.namespace', {
      paymentMethodToken: state => state?.nonSensitiveValues?.paymentMethodToken
    })
  },
  watch: {
    dnaCards: {
      deep: true,
      handler: 'setInputValue'
    },
    paymentMethodToken(value) {
      this.setInputValue(value)
    }
  },
  methods: {
    setInputValue(val) {
      let value = ''
      if (this.paymentMethodToken && this.dnaCards) {
        const isTokenForced = this.formMode === 'token'
        const card = isTokenForced
          ? this.dnaCards[0]
          : this.getSelectedToken(this.dnaCards)
        const fieldConf = card.fields[this.fieldName]
        if (fieldConf && fieldConf.value) {
          value = fieldConf.value
          // Permanent focus (for the themes that require it)
          this.setPermanentFocus()
          // Set the right input value
          if (this.hasOwnProperty('selectedOption')) {
            this.selectedOption = value
          } else if (this.hasOwnProperty('fieldValue')) {
            this.fieldValue = value
          }
        }
      }
    },
    getSelectedToken(cards) {
      for (const card of cards) {
        if (card.token === this.paymentMethodToken) return card
      }
    }
  }
}
