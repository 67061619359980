<template lang="pug">
  .kr-skeleton-wrapper
    .kr-skeleton.kr-smart-button(:class="dynamicClasses")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SmartButtonSkeleton',
  computed: {
    ...mapGetters(['isSinglePaymentButton']),
    dynamicClasses() {
      return {
        'kr-skeleton--spbm': this.isSinglePaymentButton
      }
    }
  }
}
</script>
