<template lang="pug">
    extends templates/SelectField.pug
</template>

<script>
import { isUndefined, isNaN, isEqual, find } from 'underscore'
import { mapGetters, mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'
import Events from '@/configuration/Events'

/** MIXINS */
import { SelectFieldMixin } from '@/host/components/mixins/SelectField'
import { InstallmentMixin } from '@/host/components/mixins/Installment'

export default {
  name: 'KryptonInstallments',
  mixins: [SelectFieldMixin, InstallmentMixin],
  inject: ['context'],
  data() {
    return { fieldName: 'installmentNumber' }
  },
  computed: {
    ...mapState(['amount', 'country', 'hasInterests', 'contentLabels']),
    ...mapState({
      dnaAmount: state => state.dna.amount
    }),
    ...mapGetters(['translate', 'getAmountLabel']),
    ...dynamicMapState('context.namespace', ['brandFallbacks']),
    ...dynamicMapState('context.namespace', {
      binCuotas: state => state.binOptions.cuotas
    }),
    isReadOnly() {
      const hasBrandOption = find(this.availableOptions, x => !!x.brand)
      return (
        this.isDisabled ||
        this.isUnitaryTest ||
        this.isDefaultBrand ||
        (this.isDebitBrand && !hasBrandOption) ||
        this.availableOptions.length === 1
      )
    },
    hasInstallments() {
      return (
        this.selectedBrand === 'DEFAULT' && this.configuration?.hidden === false
      )
    },
    selectedInstallment() {
      const option = find(
        this.availableOptions,
        option => option.id === this.selectedOption
      )

      let installmentCount = null
      let hasInterests = null
      let totalAmount = null
      const brand = option?.brand ? option.brand : null
      if (this.selectedOption === '-1' && !this.isDefaultBrand) {
        installmentCount = 1
        hasInterests = false
        totalAmount = this.amount
      } else if (option && option.id !== '-1') {
        installmentCount = option.count
        hasInterests = !!option.hasInterests
        totalAmount = option.totalAmount
      }

      return { installmentCount, totalAmount, hasInterests, brand }
    }
  },
  watch: {
    binCuotas: 'resetData',
    selectedInstallment: {
      deep: true,
      handler(selectedInstallment, oldSelectedInstallment) {
        const installment = { ...selectedInstallment }
        if (selectedInstallment.brand) {
          this.paymentBrand = selectedInstallment.brand
        } else if (
          !this.isDefaultBrand &&
          installment.installmentCount &&
          !(this.selectedBrand in this.brandFallbacks)
        ) {
          this.paymentBrand = this.selectedBrand
          installment.brand = this.selectedBrand
        }

        if (!isEqual(installment, oldSelectedInstallment)) {
          this.$bus.$emit(
            Events.krypton.installments.change,
            this.context.formId,
            installment
          )
        }
      }
    }
  },
  methods: {
    /**
     * Generate an available option from the given data
     */
    generateOption(value, key) {
      const values = value.split(':')
      // Values
      const id = key
      let count = parseInt(id)
      const amount = values[1]
      const hasInterests = values[2] !== '' ? parseInt(values[2]) : undefined
      const dnaLabel = values[3]
      // For ahora options, use the first values as count (id != count)
      if (`${dnaLabel}`.match(/Ahora/)) count = parseInt(values[0])
      const brand = values[4]
      const totalAmount =
        !values[5] && !hasInterests ? this.amount : parseInt(values[5])

      // Get the proper label
      const label = this.generateLabel(
        count,
        amount,
        hasInterests,
        totalAmount,
        dnaLabel
      )

      return { id, count, amount, hasInterests, label, brand, totalAmount }
    },

    /**
     * Generate the label with the given data
     */
    generateLabel(count, amount, hasInterests, totalAmount, dnaLabel) {
      const susForm = count === 1 ? 'singular' : 'plural'

      // Label
      let label
      if (!this.contentLabels.installmentNumber[susForm]) {
        // If label is define in dna take it
        if (dnaLabel && dnaLabel.length) {
          label = `${dnaLabel}`
          // if plan ahora, calculate label
          if (label?.match(/Ahora/)) {
            label = 'Ahora [COUNT] de [CURRENCY] [AMOUNT]'
          }
        } else {
          if (count === -1) {
            label =
              this.formPlaceholder[
                `singlePayment${!this.isDebitBrand ? 'Credit' : ''}`
              ] ||
              this.translate(
                `installment_single_payment${
                  !this.isDebitBrand ? '_credit' : ''
                }`
              )
          } else {
            // Else get label from translator
            let type = 'simple'
            if (
              amount.length &&
              !isUndefined(hasInterests) &&
              hasInterests <= 1
            ) {
              type = 'with' + (hasInterests === 0 ? '' : 'out') + '_int'
            } else if (hasInterests === 2) {
              type = 'with_mini_cuotas'
            } else if (hasInterests === 3) {
              type = 'with_cuotas'
            } else if (hasInterests === 4) {
              type = 'with_tasa_0'
            }
            label = this.translate(`installments_${type}_${susForm}`)
          }
        }
      } else {
        label = `${this.contentLabels.installmentNumber[susForm]}`
      }

      // Replacements
      label = label.replace('[COUNT]', count)
      if (amount.length) {
        amount = this.getAmountLabel(amount)
        label = label.replace('[CURRENCY] [AMOUNT]', amount)
      }
      if (this.bankName) label += ` - ${this.bankName}`
      if (this.country === 'AR' && totalAmount)
        label += ` (${this.getAmountLabel(totalAmount)})`

      return label
    },

    sortOptions() {
      this.availableOptions.sort((a, b) => a.count - b.count)
    },

    generatePlaceholder() {
      const type = {}
      if (this.isWalletMyCards && !this.paymentMethodToken) {
        type.name = 'wallet'
        type.translation = 'installments_wallet'
      } else if (this.isDefaultBrand) {
        type.name = 'default'
        type.translation = 'placeholder_default_installment_number'
      } else if (this.hasNoOptions) {
        type.name = `singlePayment${!this.isDebitBrand ? 'Credit' : ''}`
        type.translation = `installment_single_payment${
          !this.isDebitBrand ? '_credit' : ''
        }`
      }

      if (this.formPlaceholder[type.name]) {
        return this.formPlaceholder[type.name]
      } else if (type.name) {
        return this.translate(type.translation)
      }
    },

    manageSelectedOption(value) {
      for (const option of this.availableOptions) {
        if (option.id === value) {
          const hasInterests = option.hasInterests === true
          let amount = this.dnaAmount
          // Total amount defined, update the amount label (For all)
          if (option.totalAmount) {
            amount = option.totalAmount
          } else {
            this.$store.dispatch('update', { hasInterests })
          }

          this.$store.dispatch('update', { amount, hasInterests })
          break
        }
      }
    }
  }
}
</script>
