<template lang="pug">  
  .kr-split-payment-panel(v-onresize="onResize" v-if="hasSplitPayments")
    .kr-split-payment-label {{translate("split_payment_label")}}
    TransitionGroup(name="test" tag="div" class="kr-split-payments") 
      .kr-split-payment(v-for="{amount, brand, pan, uuid}, i in transactions" :key="i")
        .kr-split-payment-remove-icon-container
          button.kr-split-payment-remove-icon(type="button", @click="cancelSplitPayment(uuid)", v-html="removeIcon")
        BrandIcon(:method="brand")
        SmartFormMaskedCardLabel(:value="pan")
        span.kr-split-payment-amount {{getAmountLabel(amount)}}
    .kr-split-payment-remaining
      span.kr-split-payment-remaining-label {{translate("split_payment_left")}}
      span.kr-split-payment-remaining-amount {{getAmountLabel(pendingAmount)}}
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PreloadedAssets from '@/configuration/PreloadedAssets'
import BrandIcon from '@/host/components/controls/BrandIcon'
import DomReactMixin from '@/host/components/mixins/DomReact'
import SmartFormMaskedCardLabel from '@/host/components/smartform/MaskedCardLabel'

export default {
  name: 'SplitPaymentPanel',
  components: { BrandIcon, SmartFormMaskedCardLabel },
  mixins: [DomReactMixin],
  data() {
    return {
      removeIcon: PreloadedAssets.remove.remove_icon
    }
  },
  computed: {
    ...mapGetters(['getAmountLabel', 'translate', 'hasSplitPayments']),
    ...mapState({
      transactions: state => state.splitPayment.transactions,
      pendingAmount: state => state.amount,
      initialAmount: state => state.initialAmount
    })
  },
  watch: {
    hasSplitPayments(val) {
      if (!val) this.$emit('height-update', 0)
    }
  },
  methods: {
    cancelSplitPayment(transactionUuid) {
      this.$proxy.send(
        this.$storeFactory.create('declinePartialPaymentCall', {
          transactionUuid
        })
      )
    },
    onResize(e) {
      this.$emit('height-update', e.height)
    }
  }
}
</script>
