import { render, staticRenderFns } from "./PaymentButton.vue?vue&type=template&id=6cb6421b&lang=pug&"
import script from "./PaymentButton.vue?vue&type=script&lang=js&"
export * from "./PaymentButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/KRYPTON_KJS_build_master/proxy-purebilling/krypton-js-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6cb6421b')) {
      api.createRecord('6cb6421b', component.options)
    } else {
      api.reload('6cb6421b', component.options)
    }
    module.hot.accept("./PaymentButton.vue?vue&type=template&id=6cb6421b&lang=pug&", function () {
      api.rerender('6cb6421b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/host/components/extrasform/PaymentButton.vue"
export default component.exports