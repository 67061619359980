<template lang="pug">
  .kr-extras-form(v-if="isExtrasFormVisible")
    ExtrasFormModal(v-if="displayMode === 'modal'")
      span.kr-extras-form-header {{ header | capitalize | colon }}
      ExtrasFormContent
    template(v-if="displayMode === 'embedded'")
      SmartFormModalBackButton(v-if="isSmartFormPopin", :visible="true")
      span.kr-extras-form-header {{ header | capitalize | colon }}
      ExtrasFormContent
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ExtrasFormModal from './Modal'
import ExtrasFormContent from './Content'
import SmartFormModalBackButton from '@/host/components/smartform/ModalBackButton'

export default {
  name: 'ExtrasForm',
  components: {
    ExtrasFormModal,
    ExtrasFormContent,
    SmartFormModalBackButton
  },
  provide() {
    return {
      context: this.context
    }
  },
  props: {
    displayMode: {
      type: String,
      default: 'modal'
    }
  },
  data() {
    return {
      context: {
        formId: null,
        namespace: null
      }
    }
  },
  computed: {
    ...mapState(['redirectIframeOpen']),
    ...mapGetters([
      'isExtrasFormVisible',
      'isSmartFormPopin',
      'isSmallScreen',
      'requireFullScreenRedirect',
      'translate'
    ]),
    ...mapState({
      numberOfFields: state => Object.keys(state.extrasForm.dna).length
    }),
    header() {
      const translationKey =
        this.numberOfFields === 1 ? 'extra_field_header' : 'extra_fields_header'
      return this.translate(translationKey)
    }
  },

  created() {
    const { forms } = this.$store.state
    if (this.cardsFormExpanded) {
      this.context.formId = forms.clone
    } else {
      this.context.formId = forms.main
    }
    this.context.namespace = `cardForm_${this.context.formId}`
  }
}
</script>
