import { mapGetters, mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'
import debugTestCards from '@/configuration/sources/TestCards.yml'
import { isArray, has } from 'underscore'

export const TestCardMixin = {
  computed: {
    ...dynamicMapState('context.namespace', ['testCard']),
    ...mapGetters(['isExtrasFormVisible', 'isApplePaySimulator']),
    ...mapState({
      testBrand() {
        return this.testCard?.split('.')[0]?.toUpperCase()
      },
      testCardConfiguration(state) {
        return state.dna?.cards[this.testBrand]?.fields[this.fieldName] || {}
      }
    }),
    shouldPrefillExtrasForm() {
      return this.isExtrasFormVisible && this.isApplePaySimulator
    }
  },
  watch: {
    testCard(val) {
      if (val) this.changeValueTestCard()
    }
  },
  created() {
    if (this.shouldPrefillExtrasForm) this.changeValueTestCard()
  },
  methods: {
    changeValueTestCard() {
      let value = debugTestCards.fields[this.fieldName]
      if (this.testCardConfiguration.value)
        value = this.testCardConfiguration.value
      if (
        !this.testCardConfiguration.hidden &&
        !this.isDisabled &&
        !this.paymentMethodToken &&
        value
      ) {
        // Set the values
        if (this.fieldName === 'identityDocumentType') {
          this.selectedOption = this.getIdType(value)
        } else if (
          this.hasOwnProperty('selectedOption') &&
          ~this.getOptionIndexWithId(value)
        ) {
          this.selectedOption = value
        } else if (this.hasOwnProperty('fieldValue')) {
          this.fieldValue = `${value}`
          this.onInput()
        }
      }
    },
    getIdType(value) {
      const values = isArray(value) ? value : [value]
      for (const val of values)
        if (has(this.testCardConfiguration.values, val)) return val

      return this.selectedOption
    }
  }
}
