var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.headerVisible,
          expression: "headerVisible",
        },
      ],
      staticClass: "kr-smart-form-embedded-header",
      style: _vm.styles.wrapper,
    },
    [
      _c(
        "div",
        {
          ref: "amountWrapper",
          staticClass: "kr-amount-wrapper",
          class: { "kr-expanded-header": this.hasSplitPayments },
        },
        [
          _c(
            "span",
            {
              ref: "amountLabel",
              staticClass: "kr-amount-label",
              style: _vm.styles.amount.label,
            },
            [_vm._v(_vm._s(_vm.translate("amount")))]
          ),
          _c(
            "span",
            {
              ref: "amountFigure",
              staticClass: "kr-amount-figure",
              style: _vm.styles.amount.figure,
            },
            [
              _vm._v(
                _vm._s(
                  _vm.hasSplitPayments
                    ? _vm.getAmountLabel(_vm.initialAmount)
                    : _vm.amountLabel
                )
              ),
            ]
          ),
        ]
      ),
      _c("SplitPaymentPanel"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }