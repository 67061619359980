import { pick } from 'underscore'
import storeConfiguration from '@/configuration/StoreConfiguration'

const handler = (
  answer,
  { application, $store, proxy, storeFactory },
  isSmartFormRedirection = false
) => {
  // If popupPayment, force answer to json
  if (answer._name === 'popupPayment') answer = answer.json()
  const { formId } = answer
  const isSmartPayment = isSmartFormRedirection || application !== 'ghost'

  if (answer?.clientAnswer?.orderStatus) {
    const validAnswer =
      answer.hash && answer.hashAlgorithm && (answer._type || answer.type)
    switch (answer.clientAnswer.orderStatus.toLowerCase()) {
      case 'unpaid':
        $store.dispatch('error', { formId, metadata: { answer } })
        break
      case 'paid':
      case 'running':
        if (validAnswer) {
          const paymentStore = createPaymentStore(answer, formId, storeFactory)
          if (isSmartPayment) $store.dispatch('saveTransaction', paymentStore)
          else proxy.send(paymentStore)
        }

        break
    }
  } else {
    // Unexpected response, look for error info
    $store.dispatch('error', { formId, metadata: { answer } })
  }
}

const eventListener = ($locator, callback, isSmartFormRedirection = false) => {
  return ({ data: { action, data } }) => {
    if (action === 'validate') {
      data = data.replace(/"clientAnswer":"({.*})"/, '"clientAnswer":$1')
      handler(JSON.parse(data), $locator, isSmartFormRedirection)
      callback()
    } else if (action === 'close') {
      const app = $locator.application
      // For popup, dispatch the action to close and trigger the redirection
      if (app === 'host') $locator.$store.dispatch('closeWindow')
      // For iframe, send the action to navigate back
      else $locator.proxy.send($locator.storeFactory.create('navigateBack'))

      window.removeEventListener('message', eventListener($locator))
    }
  }
}

const createPaymentStore = (answer, formId, storeFactory) => {
  const payment = pick(answer, storeConfiguration.payment.fields)
  payment.formId = formId
  if (!payment.extra) payment.extra = {}
  if (answer.hashKey) payment.hashKey = answer.hashKey
  if (!payment.rawClientAnswer && answer?.clientAnswer)
    payment.rawClientAnswer = JSON.stringify(answer.clientAnswer)
  return storeFactory.create('payment', payment)
}

const messageListener = (
  $locator,
  callback = () => {},
  isSmartFormRedirection = false
) => {
  window.addEventListener(
    'message',
    eventListener($locator, callback, isSmartFormRedirection),
    false
  )
}

export default { handler, messageListener }
