export const getSplitPaymentState = () => {
  return {
    splitPayment: {
      transactions: []
    }
  }
}

export const splitPaymentActions = ($locator, app) => {
  return {
    async addSplitTransaction({ state, commit }, clientAnswer) {
      if (!state.initialAmount)
        commit('UPDATE', { initialAmount: state.amount })

      // Get new DNA
      const { updatedDna } = clientAnswer.orderDetails
      const parsedUpdatedDna = JSON.parse(updatedDna)
      await $locator.dna.parse(parsedUpdatedDna)

      // Newest transaction will always be first in the list
      const newTransaction = clientAnswer.transactions[0]
      const { effectiveAmount, cardDetails } = newTransaction.transactionDetails
      const { pan, effectiveBrand } = cardDetails

      const { transactions } = state.splitPayment
      const newTransactions = [
        ...transactions,
        {
          amount: effectiveAmount,
          brand: effectiveBrand,
          pan,
          uuid: newTransaction.uuid
        }
      ]

      commit('UPDATE', {
        splitPayment: {
          transactions: newTransactions
        }
      })
    },
    async removeSplitTransaction(
      { state, commit },
      { transactionUuid, clientAnswer }
    ) {
      const { transactions } = state.splitPayment
      const { updatedDna } = clientAnswer.orderDetails

      const parsedUpdatedDna = JSON.parse(updatedDna)
      await $locator.dna.parse(parsedUpdatedDna)

      const newTransactions = transactions.filter(
        transaction => transaction.uuid !== transactionUuid
      )

      commit('UPDATE', {
        splitPayment: {
          transactions: newTransactions
        }
      })
    }
  }
}

export const splitPaymentGetters = {
  hasSplitPayments: ({ splitPayment: { transactions } }) => {
    return transactions.length > 0
  }
}
