import TestCards from '@/configuration/sources/TestCards.yml'

export const formatCardNumber = (cardNumber, brand) => {
  if (typeof cardNumber !== 'string' && typeof cardNumber !== 'number')
    return cardNumber

  cardNumber = `${cardNumber}`

  if (brand === 'AMEX') {
    const match = /(.{4})(.{6})(.{5})/g.exec(cardNumber)
    return match.slice(1, match.length).join(' ')
  } else {
    return cardNumber.match(/.{1,4}/g).join(' ')
  }
}

/**
 * Based on @/configuration/sources/TestsCard.yml configuration:
 *
 * Given a testcard path (e.g: visa.acceptedWithStrongAuthentication),
 * return an object with:
 *  - The uppercase brand (e.g: VISA)
 *  - The pan
 *  - The associated test CVV if provided in the configuration, else undefined.
 *  - The associated expiry test if provided, else undefined
 *
 * This method was originally written in the Slave mixin TestCards but has been
 * extracted here to be unitary testable, and eventually reused elsewhere.
 *
 * @see KJS-2422
 */
export const getTestCardFromDebugTestCards = testCard => {
  const splittedPath = testCard.split('.')
  const brand = splittedPath[0].toUpperCase()
  const status = splittedPath[1]
  const subStatus = splittedPath[2].replace(/-/g, '_')
  const cardBrand = splittedPath[3]
  const currency = splittedPath[4]
  const cardNumbers = TestCards['brands'][brand][status]

  for (const cardNum in cardNumbers) {
    const card = cardNumbers[cardNum]
    if (
      (card === subStatus || card.translation === subStatus) &&
      card.currency === currency &&
      card.brand === cardBrand
    ) {
      return {
        brand: cardBrand ? cardBrand : brand,
        pan: cardNum,
        cvv: card.cvv,
        expiryDate: card.expiryDate
      }
    }
  }
  return null
}
