var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      directives: [
        {
          name: "onresize",
          rawName: "v-onresize",
          value: _vm.onGridChange,
          expression: "onGridChange",
        },
      ],
      staticClass: "kr-method-btn",
      class: [_vm.dynamicClasses, _vm.methodClasses, _vm.buttonLabelClasses],
      style: [_vm.styles.button, _vm.gridStyles],
      attrs: {
        type: "button",
        "kr-payment-method": _vm.method.key.toLowerCase(),
      },
      on: {
        click: function ($event) {
          return _vm.openMethod(_vm.method)
        },
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
      },
    },
    [
      _vm.hasRadioButton
        ? _c("SmartFormRadioButton", {
            ref: "radioButton",
            attrs: { checked: _vm.isSelected, hover: _vm.hover },
          })
        : _vm._e(),
      _c(
        "div",
        { ref: "icon", staticClass: "kr-icon" },
        [
          _c("BrandIcon", {
            attrs: { method: _vm.method.key, compact: !_vm.showLabel },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "kr-method-btn__label", class: [_vm.labelClasses] },
        [
          _c("label", { ref: "label" }, [
            _vm._v(
              _vm._s(
                _vm.getCustomPaymentMethodLabel(_vm.method.key) ||
                  _vm.methodLabel
              )
            ),
          ]),
        ]
      ),
      _vm.isLoading
        ? _c("KryptonLoadingAnimation", { ref: "loading" })
        : _c("SmartFormMethodBadge", {
            attrs: { method: _vm.method.key, compact: !_vm.showLabel },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }